import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "font-awesome/css/font-awesome.css";
import {
  Table,
  TableColumn,
  Tabs,
  TabPane,
  Pagination,
  Select,
  Option,
  Input,
  Tooltip,
  Row,
  Col,
  DatePicker,
  Drawer,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Button,
  Dialog,
  Form,
  FormItem,
  Divider,
  Link,
  Message,
  MessageBox,
  InputNumber,
  Tag,
  Switch,
  Breadcrumb,
  BreadcrumbItem,
  Descriptions,
  DescriptionsItem,
  Radio,
  Steps,
  Step,
  Tree,
  Checkbox,
  Cascader,
  Menu,
  MenuItem,
  Submenu,
  Alert,
  CheckboxGroup,
  Upload,
  RadioGroup,
  Popover,
  Empty,
  Loading,
  TimeSelect,
  TimePicker,
  Progress,
} from "element-ui";

import "@/icons";
import "@/components/ef/index.css";
import "../src/assets/css/ionicons.min.css";

import Vue2Editor from "vue2-editor";


import "@/assets/css/common.css";
import Print from "vue-print-nb";
import "./permission";
Vue.config.productionTip = false;


import xiaobu from "vue2-water-marker"
Vue.use(xiaobu)
Vue.use(Table)
  .use(TableColumn)
  .use(Tabs)
  .use(TabPane)
  .use(Pagination)
  .use(Select)
  .use(Option)
  .use(Input)
  .use(Tooltip)
  .use(Row)
  .use(Col)
  .use(DatePicker)
  .use(Drawer)
  .use(Dropdown)
  .use(DropdownItem)
  .use(DropdownMenu)
  .use(Button)
  .use(Dialog)
  .use(Form)
  .use(FormItem)
  .use(Divider)
  .use(Link)
  .use(InputNumber)
  .use(Tag)
  .use(Switch)
  .use(Breadcrumb)
  .use(BreadcrumbItem)
  .use(Descriptions)
  .use(DescriptionsItem)
  .use(Radio)
  .use(Steps)
  .use(Step)
  .use(Tree)
  .use(Checkbox)
  .use(Cascader)
  .use(Menu)
  .use(MenuItem)
  .use(Submenu)
  .use(Alert)
  .use(CheckboxGroup)
  .use(Upload)
  .use(RadioGroup)
  .use(Popover)
  .use(Empty)
  .use(Loading.directive)
  .use(TimeSelect)
  .use(TimePicker)
  .use(Progress);
Vue.use(Print);
Vue.use(Vue2Editor);
Vue.prototype.$message = Message;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$loading = Loading.service;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
