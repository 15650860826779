import request from "@/utils/request";
//获取图形验证码
export function getCaptcha() {
  return request({
    url: "/api/v1/captcha/get",
    method: "get",
  });
}

//用户登录
export function setLogin(data) {
  return request({
    url: "/api/v1/login",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
}

//退出登录
export function setExit(params) {
  return request({
    url: "/api/v1/exit",
    method: "get",
    params,
  });
}

//用户信息
export function getUserinfo(params) {
  return request({
    url: `/api/v1/sysUser/${params.id}`,
    method: "get",
    params,
  });
}

//角色管理-角色列表
export function getRoles(params) {
  return request({
    url: "/api/v1/roles",
    method: "get",
    params,
  });
}

//角色管理-新增角色
export function addRoles(data) {
  return request({
    url: "/api/v1/role/add",
    method: "post",
    data,
  });
}

//角色管理-编辑角色
export function updateRoles(data) {
  return request({
    url: "/api/v1/role/update",
    method: "post",
    data,
  });
}

//角色管理-删除角色
export function deleteRoles(data) {
  return request({
    url: "/api/v1/role/batchDelete",
    method: "delete",
    data,
  });
}

//获取全部角色
export function getRoleAll(params) {
  return request({
    url: `/api/v1/role/all`,
    method: "get",
    params,
  });
}

//点击角色菜单获取对应权限
export function getPermissions(params) {
  return request({
    url: `/api/v1/roles/${params.id}/permissions`,
    method: "get",
    params,
  });
}
//保存角色菜单获取对应权限
export function savePermissions(data) {
  return request({
    url: `/api/v1/permission/save`,
    method: "post",
    data,
  });
}

//组织机构-项目部列表
export function getProjects(params) {
  return request({
    url: "/api/v1/projects",
    method: "get",
    params,
  });
}
//获取项目部详情
export function getProjectsId(params) {
  return request({
    url: `/api/v1/projects/${params.id}`,
    method: "get",
    params,
  });
}

//获取项目部-列表子项目
export function getProjectsChildren(params) {
  return request({
    url: `/api/v1/projects/${params.id}/children`,
    method: "get",
    params,
  });
}

//批量修改上级项目部
export function batchUpdateProject(data) {
  return request({
    url: `/api/v1/project/batchUpdate`,
    method: "post",
    data,
  });
}

// 获取上级项目部
export function getProjectsTree(data) {
  return request({
    url: `/api/v1/project/tree`,
    method: "post",
    data,
  });
}
//组织机构-新增项目部
export function addProject(data) {
  return request({
    url: "/api/v1/project/add",
    method: "post",
    data,
  });
}
//组织机构-编辑项目部
export function updateProject(data) {
  return request({
    url: "/api/v1/project/update",
    method: "post",
    data,
  });
}
//组织机构-删除项目部
export function deleteProject(params) {
  return request({
    url: "/api/v1/project/delete",
    method: "delete",
    params,
  });
}

//组织机构-单位列表
export function getCompanies(params) {
  return request({
    url: "/api/v1/companies",
    method: "get",
    params,
  });
}

//组织机构-新增单位
export function addCompanies(data) {
  return request({
    url: "/api/v1/company/add",
    method: "post",
    data,
  });
}

//组织机构-编辑单位
export function updateCompanies(data) {
  return request({
    url: "/api/v1/company/update",
    method: "post",
    data,
  });
}

//组织机构-删除单位
export function deleteCompanies(params) {
  return request({
    url: "/api/v1/company/delete",
    method: "delete",
    params,
  });
}

//组织机构-部门列表
export function getDepartments(params) {
  return request({
    url: "/api/v1/departments",
    method: "get",
    params,
  });
}
//获取部门-列表子项目
export function getDepartmentsChildren(params) {
  return request({
    url: `/api/v1/departments/${params.id}/children`,
    method: "get",
    params,
  });
}
//获取上级部门
export function getDepartmentsTree(data) {
  return request({
    url: `/api/v1/department/tree`,
    method: "post",
    data,
  });
}

//组织机构-新增部门
export function addDepartments(data) {
  return request({
    url: `/api/v1/department/add`,
    method: "post",
    data,
  });
}
//组织机构-更新部门
export function updateDepartments(data) {
  return request({
    url: `/api/v1/department/update`,
    method: "post",
    data,
  });
}
//组织机构-删除部门
export function deleteDepartments(data) {
  return request({
    url: `/api/v1/department/batchDelete`,
    method: "delete",
    data,
  });
}
//组织机构-岗位职务
export function getPositions(params) {
  return request({
    url: "/api/v1/positions",
    method: "get",
    params,
  });
}
//岗位新增
export function addPositions(data) {
  return request({
    url: "/api/v1/position/add",
    method: "post",
    data,
  });
}
//岗位编辑
export function updatePositions(data) {
  return request({
    url: "/api/v1/position/update",
    method: "post",
    data,
  });
}
//岗位删除
export function deletePositions(params) {
  return request({
    url: "/api/v1/position/delete",
    method: "delete",
    params,
  });
}

export function getPositionTemplate(params) {
  return request({
    url: "/api/v1/position/import/template",
    method: "get",
    params,
  });
}

export function getPositionImport(data) {
  return request({
    url: "/api/v1/position/import",
    method: "post",
    data,
  });
}

export function getPositionExport(params) {
  return request({
    url: "/api/v1/position/export",
    method: "get",
    params,
  });
}

// 组织关系
export function getOrganizations(params) {
  return request({
    url: "/api/v1/organizations",
    method: "get",
    params,
  });
}

export function getOrganizationsChildren(params) {
  return request({
    url: `/api/v1/organizations/${params.id}/children`,
    method: "get",
    params,
  });
}
export function updateOrganizations(data) {
  return request({
    url: "/api/v1/organizations/update",
    method: "post",
    data,
  });
}
export function getOrganizationsTree(params) {
  return request({
    url: "/api/v1/organization/tree",
    method: "get",
    params,
  });
}

export function addOrganizations(data) {
  return request({
    url: "/api/v1/organizations/add",
    method: "post",
    data,
  });
}

export function deleteOrganizations(params) {
  return request({
    url: "/api/v1/organization/delete",
    method: "delete",
    params,
  });
}

//审批管理-流程管理列表
export function getWorkflows(params) {
  return request({
    url: "/api/v1/workflows",
    method: "get",
    params,
  });
}

//审批管理-流程管理新建
export function addWorkflows(data) {
  return request({
    url: "/api/v1/workflow/add",
    method: "post",
    data,
  });
}

//审批管理-流程管理编辑
export function updateWorkflows(data) {
  return request({
    url: "/api/v1/workflow/update",
    method: "post",
    data,
  });
}
//审批管理-流程管理删除
export function deleteWorkflows(params) {
  return request({
    url: "/api/v1/workflow/delete",
    method: "delete",
    params,
  });
}
//获取流程管理,主表和子表
export function getWorkflowFields(params) {
  return request({
    url: "/api/v1/workflow/fields",
    method: "get",
    params,
  });
}

// 节点数据
export function getNodeDic(params) {
  return request({
    url: "/api/v1/workflow/nodeDic",
    method: "get",
    params,
  });
}
// 业务表数据
export function getTables(params) {
  return request({
    url: "/api/v1/workflow/tables",
    method: "get",
    params,
  });
}

//审批管理-我的审批列表

export function getWorkflowTable(params) {
  return request({
    url: "/api/v1/workflowTable",
    method: "get",
    params,
  });
}

export function getWorkflowTableList(params) {
  return request({
    url: `/api/v1/workflowTable/${params.type}/list`,
    method: "get",
    params,
  });
}

export function deleteWorkflowTable(params) {
  return request({
    url: `/api/v1/workflowTable/${params.type}/delete`,
    method: "delete",
    params,
  });
}

export function getWorkFlowTableSteps(data) {
  return request({
    url: `/api/v1/workFlowTableSteps`,
    method: "post",
    data,
  });
}

export function getWorkFlowTableStepsType(data) {
  return request({
    url: `/api/v1/workflowTable/${data.type}/steps`,
    method: "post",
    data,
  });
}

//获取可转办事项条目
export function getWorkFlowTableStats(params) {
  return request({
    url: `/api/v1/workflowTable/todo/stats`,
    method: "get",
    params,
  });
}

// 动态审批
export function setAudit(data) {
  return request({
    url: `/api/v1/${data.tableName}/audit`,
    method: "post",
    data,
  });
}

// 系统菜单
export function getModuleList(data) {
  return request({
    url: "/api/v1/module/tree",
    method: "post",
    data,
  });
}

export function getModuleListId(params) {
  return request({
    url: `/api/v1/module/${params.id}`,
    method: "get",
    params,
  });
}

export function addModuleListId(data) {
  return request({
    url: `/api/v1/module/add`,
    method: "post",
    data,
  });
}

export function updateModuleListId(data) {
  return request({
    url: `/api/v1/module/update`,
    method: "post",
    data,
  });
}

export function deleteModuleListId(params) {
  return request({
    url: `/api/v1/module/delete`,
    method: "delete",
    params,
  });
}

export function getButtonsList(params) {
  return request({
    url: `/api/v1/buttons/list`,
    method: "get",
    params,
  });
}

//成本中心
export function getCostCenters(params) {
  return request({
    url: `/api/v1/costCenters`,
    method: "get",
    params,
  });
}

export function addCostCenters(data) {
  return request({
    url: `/api/v1/costCenter/add`,
    method: "post",
    data,
  });
}
export function updateCostCenters(data) {
  return request({
    url: `/api/v1/costCenter/update`,
    method: "post",
    data,
  });
}
export function deleteCostCenters(data) {
  return request({
    url: `/api/v1/costCenter/batchDelete`,
    method: "delete",
    data,
  });
}

export function getCostCentersTemplate(params) {
  return request({
    url: `/api/v1/costCenters/import/template`,
    method: "get",
    params,
  });
}

export function getCostCentersImport(data) {
  return request({
    url: `/api/v1/costCenters/import`,
    method: "post",
    data,
  });
}
export function getCostCentersExport(params) {
  return request({
    url: `/api/v1/costCenters/export`,
    method: "get",
    params,
  });
}
export function getOrganizationTree(params) {
  return request({
    url: `/api/v1/organization/tree`,
    method: "get",
    params,
  });
}

// 权限配置
export function getMenuTree(params) {
  return request({
    url: `/api/v1/users/${params.id}/getMenuTree`,
    method: "get",
    params,
  });
}
export function getModules(params) {
  return request({
    url: `/api/v1/modules`,
    method: "get",
    params,
  });
}

// 获取全部项目,部门,单位
export function getProjectAll(params) {
  return request({
    url: `/api/v1/project/all`,
    method: "get",
    params,
  });
}
export function getCompanyAll(params) {
  return request({
    url: `/api/v1/company/all`,
    method: "get",
    params,
  });
}
export function getDepartmentAll(params) {
  return request({
    url: `/api/v1/department/all`,
    method: "get",
    params,
  });
}

//费用分割单
export function getCostAllocationListl(params) {
  return request({
    url: `/api/v1/${params.costAllocation}/list`,
    method: "get",
    params,
  });
}
export function addCostAllocation(data) {
  return request({
    url: `/api/v1/${data.costAllocation}/add`,
    method: "post",
    data,
  });
}
export function editCostAllocation(data) {
  return request({
    url: `/api/v1/${data.costAllocation}/edit`,
    method: "post",
    data,
  });
}

export function getCostAllocationItem(params) {
  return request({
    url: `/api/v1/${params.costAllocation}/${params.key}`,
    method: "get",
    params,
  });
}

export function getCostAllocationItem2(params) {
  return request({
    url: `/api/v1/employee/leaveApplication/${params.key}`,
    method: "get",
    params,
  });
}

export function deleteCostAllocationItem(params) {
  return request({
    url: `/api/v1/${params.costAllocation}/delete`,
    method: "delete",
    params,
  });
}

//上传附件
export function uploadAttachment(data) {
  return request({
    url: `/api/v1/form/attachment/upload`,
    method: "post",
    headers: {
      "content-type": "multipart/form-data",
    },
    data,
  });
}
//文件上传
export function upload(data) {
  return request({
    url: `/api/v1/upload`,
    method: "post",
    headers: {
      "content-type": "multipart/form-data",
    },
    data,
  });
}

//上传后做关联
export function addAttachment(data) {
  return request({
    url: `/api/v1/form/attachment/add`,
    method: "post",
    data,
  });
}

//获取全部附件
export function allAttachment(params) {
  return request({
    url: `/api/v1/form/attachment/all2`,
    method: "get",
    params,
  });
}

export function deleteAttachment(params) {
  return request({
    url: `/api/v1/form/attachment/delete`,
    method: "delete",
    params,
  });
}

//获取专业分类名称和沙化专业分类名称

export function allPositionCategory(params) {
  return request({
    url: `/api/v1/positionCategory/all`,
    method: "get",
    params,
  });
}
export function allPositionLevel(params) {
  return request({
    url: `/api/v1/positionLevel/all`,
    method: "get",
    params,
  });
}

//人力需求计划申请
export function getManpowerRequestList(params) {
  return request({
    url: `/api/v1/manpowerRequest/list`,
    method: "get",
    params,
  });
}

//人力需求计划新建
export function addManpowerRequest(data) {
  return request({
    url: `/api/v1/manpowerRequest/add`,
    method: "post",
    data,
  });
}
export function getManpowerRequestItem(params) {
  return request({
    url: `/api/v1/manpowerRequest/${params.key}`,
    method: "get",
    params,
  });
}
export function editManpowerRequest(data) {
  return request({
    url: `/api/v1/manpowerRequest/edit`,
    method: "post",
    data,
  });
}
export function deleteManpowerRequest(params) {
  return request({
    url: `/api/v1/manpowerRequest/delete`,
    method: "delete",
    params,
  });
}

//获取全部岗位职务
export function allPosition(params) {
  return request({
    url: `/api/v1/position/all`,
    method: "get",
    params,
  });
}

//根据三个id获取成本中心
export function allCostCenters(params) {
  return request({
    url: `/api/v1/costCenters/all`,
    method: "get",
    params,
  });
}

//岗位外包服务

export function getManpowerSupplierList(params) {
  return request({
    url: `/api/v1/manpowerSupplier/list`,
    method: "get",
    params,
  });
}
export function getManpowerSupplierItem(params) {
  return request({
    url: `/api/v1/manpowerSupplier/${params.key}`,
    method: "get",
    params,
  });
}

export function addManpowerSupplier(data) {
  return request({
    url: `/api/v1/manpowerSupplier/add`,
    method: "post",
    data,
  });
}

export function editManpowerSupplier(data) {
  return request({
    url: `/api/v1/manpowerSupplier/edit`,
    method: "post",
    data,
  });
}

export function deleteManpowerSupplier(params) {
  return request({
    url: `/api/v1/manpowerSupplier/delete`,
    method: "delete",
    params,
  });
}

//单价信息
export function getManpowerRecruitmentHourlyRate(params) {
  return request({
    url: `/api/v1/manpowerRecruitmentHourlyRate/list`,
    method: "get",
    params,
  });
}

export function addManpowerRecruitmentHourlyRate(data) {
  return request({
    url: `/api/v1/manpowerRecruitmentHourlyRate/add`,
    method: "post",
    data,
  });
}


export function addManpowerRecruitmentHourlyRate2(data) {
  return request({
    url: `/api/v1/manpowerRecruitmentHourlyRate/add2`,
    method: "post",
    data,
  });
}
export function editManpowerRecruitmentHourlyRate(data) {
  return request({
    url: `/api/v1/manpowerRecruitmentHourlyRate/edit`,
    method: "post",
    data,
  });
}

export function delelteManpowerRecruitmentHourlyRate(params) {
  return request({
    url: `/api/v1/manpowerRecruitmentHourlyRate/delete`,
    method: "delete",
    params,
  });
}
export function getManpowerRecruitmentHourlyRateItem(params) {
  return request({
    url: `/api/v1/manpowerRecruitmentHourlyRate/${params.key}`,
    method: "get",
    params,
  });
}

export function getManpowerRecruitmentHourlyRateTemplate(params) {
  return request({
    url: `/api/v1/manpowerRecruitmentHourlyRate/import/template`,
    method: "get",
    params,
  });
}

export function getManpowerRecruitmentHourlyRateImport(data) {
  return request({
    url: `/api/v1/manpowerRecruitmentHourlyRate/import`,
    method: "post",
    data,
  });
}

export function getManpowerRecruitmentHourlyRateExport(params) {
  return request({
    url: `/api/v1/manpowerRecruitmentHourlyRate/export/${params.key}`,
    method: "get",
    params,
  });
}
export function getManpowerRecruitmentHourlyRateStats(params) {
  return request({
    url: `/api/v1/manpowerRecruitmentHourlyRate/stats`,
    method: "get",
    params,
  });
}

//首页审批事项
export function getHomeTodo(params) {
  //待办
  return request({
    url: `/api/v1/home/todo`,
    method: "get",
    params,
  });
}
export function getHomeProcessed(params) {
  //已办
  return request({
    url: `/api/v1/home/processed`,
    method: "get",
    params,
  });
}
export function getHomeTracking(params) {
  //跟踪
  return request({
    url: `/api/v1/home/tracking`,
    method: "get",
    params,
  });
}
export function getHomeEnd(params) {
  //已结束
  return request({
    url: `/api/v1/home/end`,
    method: "get",
    params,
  });
}

//费用管理导出相关接口

// 沙化费用
export function getSaudiCosts(params) {
  return request({
    url: `/api/v1/saudiCosts`,
    method: "get",
    params,
  });
}
export function getSaudiCostExport(params) {
  return request({
    url: `/api/v1/saudiCost/export`,
    method: "get",
    params,
  });
}

// 沙化培训费用
export function getSaudiTrainingCosts(params) {
  return request({
    url: `/api/v1/saudiTrainingCosts`,
    method: "get",
    params,
  });
}
export function getSaudiTrainingCostExport(params) {
  return request({
    url: `/api/v1/saudiTrainingCost/export`,
    method: "get",
    params,
  });
}

// HRDF
export function getHRDFRefundCosts(params) {
  return request({
    url: `/api/v1/HRDFRefundCosts`,
    method: "get",
    params,
  });
}
export function getHRDFRefundCostExport(params) {
  return request({
    url: `/api/v1/HRDFRefundCost/export`,
    method: "get",
    params,
  });
}

//权限分配策略
export function addPermissionTemplate(data) {
  return request({
    url: `/api/v1/permissionTemplate/add`,
    method: "post",
    data,
  });
}

export function getPermissionTemplate(params) {
  return request({
    url: `/api/v1/permissionTemplate/list`,
    method: "get",
    params,
  });
}

export function getPermissionTemplateId(params) {
  return request({
    url: `/api/v1/permissionTemplate/{id}`,
    method: "get",
    params,
  });
}

export function deletePermissionTemplate(params) {
  return request({
    url: `/api/v1/permissionTemplate/delete`,
    method: "delete",
    params,
  });
}

//首页通知公告

export function getNews(params) {
  return request({
    url: `/api/v1/news`,
    method: "get",
    params,
  });
}

export function getNewsId(params) {
  return request({
    url: `/api/v1/news/${params.id}`,
    method: "get",
    params,
  });
}

//日志
export function getLogs(params) {
  return request({
    url: `/api/v1/logs`,
    method: "get",
    params,
  });
}

export function getLogsId(params) {
  return request({
    url: `/api/v1/logs/${params.id}`,
    method: "get",
    params,
  });
}
export function deleteLogsId(params) {
  return request({
    url: `/api/v1/logs/delete`,
    method: "get",
    params,
  });
}
//待我查阅
export function getView(params) {
  return request({
    url: `/api/v1/home/view`,
    method: "get",
    params,
  });
}
//转办
export function setTransfer(data) {
  return request({
    url: `/api/v1/workflowTable/todo/transfer`,
    method: "post",
    data,
  });
}
//获取请假列表
export function getLeaveList(params) {
  return request({
    url: `/api/v1/employee/leaveApplications`,
    method: "get",
    params,
  });
}
export function getLeaveList2(params) {
  return request({
    url: `/api/v1/employeeLeaveApplication/list`,
    method: "get",
    params,
  });
}
// 提交请假
export function addLeaveApplication(data) {
  return request({
    url: `/api/v1/employee/leaveApplication/add`,
    method: "post",
    data,
  });
}
export function addLeaveApplication2(data) {
  return request({
    url: `/api/v1/employeeLeaveApplication/add`,
    method: "post",
    data,
  });
}
//编辑请假
export function editLeaveApplication(data) {
  return request({
    url: `/api/v1/employee/leaveApplication/edit`,
    method: "post",
    data,
  });
}
export function editLeaveApplication2(data) {
  return request({
    url: `api/v1/employeeLeaveApplication/edit`,
    method: "post",
    data,
  });
}

//获取加班列表
export function getOvertimeList(params) {
  return request({
    url: `/api/v1/employee/overtimeWorkingApplications`,
    method: "get",
    params,
  });
}

export function getOvertimeList2(params) {
  return request({
    url: `/api/v1/employeeOvertimeWorkingApplication/list`,
    method: "get",
    params,
  });
}
//编辑加班
export function editOvertime(data) {
  return request({
    url: `/api/v1/employee/overtimeWorkingApplication/edit`,
    method: "post",
    data,
  });
}
export function editOvertime2(data) {
  return request({
    url: `/api/v1/employeeOvertimeWorkingApplication/edit`,
    method: "post",
    data,
  });
}
//添加加班
export function addOvertime(data) {
  return request({
    url: `/api/v1/employee/overtimeWorkingApplication/add`,
    method: "post",
    data,
  });
}
export function addOvertime2(data) {
  return request({
    url: `/api/v1/employeeOvertimeWorkingApplication/add`,
    method: "post",
    data,
  });
}

//需求计划提交简历数等
export function editProcessManpowerRequest(data) {
  return request({
    url: `/api/v1/manpowerRequest/process/edit`,
    method: "post",
    data,
  });
}

//获取消息
export function getMessages(params) {
  return request({
    url: `/api/v1/messages`,
    method: "get",
    params,
  });
}

//获取普通用户账号下数据
export function getApplication(params) {
  return request({
    url: `/api/v1/employee/application/stats`,
    method: "get",
    params,
  });
}

//根据员工编号获取员工信息
export function getUsersCode(params) {
  return request({
    url: `/api/v1/users/${params.code}`,
    method: "get",
    params,
  });
}

//获取费用项目树
export function getCostCategoryTree(data) {
  return request({
    url: `/api/v1/costCategory/tree`,
    method: "post",
    data,
  });
}

//获取一级费用项目
export function getcostCategoryRoot(params) {
  return request({
    url: `/api/v1/costCategory/root`,
    method: "get",
    params,
  });
}

// 获取二级
export function getcostCategorychildren(params) {
  return request({
    url: `/api/v1/costCategories/${params.id}/children`,
    method: "get",
    params,
  });
}

//获取我的费用列表
export function getCostsList(params) {
  return request({
    url: `/api/v1/costs`,
    method: "get",
    params,
  });
}

//获取全部用工形式
export function getEmploymentForm(params) {
  return request({
    url: `/api/v1/employmentForm/all`,
    method: "get",
    params,
  });
}

//新增费用处理新建表格
export function addcost(data) {
  return request({
    url: `/api/v1/cost/add`,
    method: "post",
    data,
  });
}

//编辑费用处理新建表格
export function editcost(data) {
  return request({
    url: `/api/v1/cost/edit`,
    method: "post",
    data,
  });
}

//查找费用详情
export function getCostItem(params) {
  return request({
    url: `/api/v1/cost/${params.id}`,
    method: "get",
    params,
  });
}

export function deleteBatchCost(data) {
  return request({
    url: "/api/v1/cost/batchDelete",
    method: "delete",
    data,
  });
}

//费用处理导入模板
export function importCostTemplate(params) {
  return request({
    url: "/api/v1/cost/import/template",
    method: "get",
    params,
  });
}

//导入
export function importCost(data) {
  return request({
    url: "/api/v1/cost/import",
    method: "post",
    data,
  });
}

//导出

export function exportCost(params) {
  return request({
    url: "/api/v1/cost/export",
    method: "get",
    params,
  });
}
