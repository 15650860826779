<template>
  <div class="main" v-if="$store.state.userInfo">
    <!-- 顶部一栏 -->
    <div class="main-top">
      <div :class="['left', { 'left-close': !openOrGate }]">
        <b v-if="openOrGate || (smallScreen && !openOrGate)">HRMS/SPEC/</b>
        <span v-if="openOrGate || (smallScreen && !openOrGate)">SNEI</span>
        <div class="sne" v-if="!openOrGate && !smallScreen">SNE</div>
      </div>
      <div class="right">
        <div class="icon" @click="changeSwitch">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </div>
        <div class="right-box">
          <div class="button-fa" @click="goYuangong">
            <button>员工中心</button>
          </div>
          <div
            :class="['user-box', { 'user-active': isUserIfo }]"
            @click="isUserIfo = true"
            v-click-outside="handleBlur"
          >
            <img
              class="user-img"
              v-if="$store.state.userInfo && $store.state.userInfo.UserPic"
              :src="$store.state.userInfo.UserPic"
            />
            <img v-else class="user-img" src="@/assets/image/1.jpg" />
            <span class="user-name" v-if="$store.state.userInfo">{{
              $store.state.userInfo.RealName
                ? $store.state.userInfo.RealName
                : $store.state.userInfo.UserName
            }}</span>
          </div>
        </div>
        <!-- 弹框 -->
        <div v-if="isUserIfo" class="pop-frame">
          <div class="user-header">
            <div class="circle-box">
              <img
                class="img-circle"
                v-if="$store.state.userInfo && $store.state.userInfo.UserPic"
                :src="$store.state.userInfo.UserPic"
              />
              <img v-else class="img-circle" src="@/assets/image/1.jpg" />
            </div>
            <p v-if="$store.state.userInfo">
              您好，{{
                $store.state.userInfo.RealName
                  ? $store.state.userInfo.RealName
                  : $store.state.userInfo.UserName
              }}！
              <small
                >上次登录时间：{{ $store.state.userInfo.LastLoginDate }}</small
              >
            </p>
          </div>
          <div class="user-footer">
            <div class="pull-left" @click="ziliao">资料</div>
            <div class="pull-right" @click="setExit">注销</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 主体内容区域 -->
    <div class="main-content">
      <!-- 导航 -->
      <div
        ref="navigation"
        :class="[
          'navigation',
          { 'navigation-close': !openOrGate && !smallScreen },
          { 'navigation-none': !openOrGate && smallScreen },
        ]"
      >
        <div class="input-box" v-if="openOrGate">
          <input
            class="form-control"
            name="keywords"
            type="text"
            placeholder="请输入菜单名称..."
          />
          <span class="search"
            ><i class="fa fa-search" aria-hidden="true"></i>
          </span>
        </div>
        <div class="main-navigation" v-if="openOrGate">MAIN NAVIGATION</div>

        <!-- 具体导航内容 -->
        <div
          v-for="(item, index) in navigation"
          :key="item.name"
          class="navigation-content"
          v-if="openOrGate"
        >
          <!-- 展开显示区域 -->
          <div
            :class="[
              'navigation-open-1',
              { 'navigation-open-1-active': item.active },
            ]"
            @click="chenageOpen1(index)"
          >
            <i :class="item.icon" aria-hidden="true"></i>
            <span> {{ item.name }}</span>
            <i
              v-if="item.subOption"
              :class="[
                'fa fa-angle-left pull-right',
                { 'pull-rotate': item.arrow },
              ]"
              aria-hidden="true"
            ></i>
          </div>

          <!-- 展开显示区域二级 -->
          <div
            v-if="item.subOption"
            :style="`height:${
              item.active ? item.children.length * 30 + item.height2 : 0
            }px`"
            class="navigation-open-2-box"
          >
            <div v-for="(cItem, cIndex) in item.children" :key="cItem.name">
              <div
                :class="[
                  'navigation-open-2',
                  { 'navigation-open-2-active': cItem.active },
                ]"
                @click="chenageOpen2(index, cIndex)"
              >
                <i class="fa fa-circle-o" aria-hidden="true"></i>
                <span>{{ cItem.name }}</span>

                <i
                  v-if="cItem.subOption"
                  :class="['fa fa-angle-left', { 'pull-rotate': cItem.arrow }]"
                  aria-hidden="true"
                ></i>
              </div>

              <!-- 展开显示区域三级 -->
              <div
                v-if="cItem.subOption"
                :style="`height:${
                  cItem.active ? cItem.children.length * 30 + item.height3 : 0
                }px`"
                class="navigation-open-3-box"
              >
                <div
                  v-for="(dItem, dIndex) in cItem.children"
                  :key="dItem.name"
                >
                  <div
                    @click="chenageOpen3(index, cIndex, dIndex)"
                    :class="[
                      'navigation-open-3',
                      { 'navigation-open-3-active': dItem.active },
                    ]"
                  >
                    <i class="fa fa-circle-o" aria-hidden="true"></i>
                    <span>{{ dItem.name }}</span>
                    <i
                      v-if="dItem.subOption"
                      :class="[
                        'fa fa-angle-left',
                        { 'pull-rotate': dItem.arrow },
                      ]"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <!-- 展开显示区域四级 -->
                  <div
                    v-if="dItem.subOption"
                    :style="`height:${
                      dItem.active
                        ? dItem.children.length * 30 + item.height4
                        : 0
                    }px`"
                    class="navigation-open-4-box"
                  >
                    <div
                      v-for="(eItem, eIndex) in dItem.children"
                      :key="eItem.name"
                    >
                      <div
                        :class="[
                          'navigation-open-4',
                          { 'navigation-open-4-active': eItem.active },
                        ]"
                        @click="chenageOpen4(index, cIndex, dIndex, eIndex)"
                      >
                        <i class="fa fa-circle-o" aria-hidden="true"></i>
                        <span>{{ eItem.name }}</span>
                        <i
                          v-if="eItem.subOption"
                          :class="[
                            'fa fa-angle-left',
                            { 'pull-rotate': eItem.arrow },
                          ]"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <!-- 展开显示区域五级 -->
                      <div
                        v-if="eItem.subOption"
                        :style="`height:${
                          eItem.active ? eItem.children.length * 30 : 0
                        }px`"
                        class="navigation-open-5-box"
                      >
                        <div
                          v-for="(fItem, fIndex) in eItem.children"
                          :key="fItem.name"
                        >
                          <div
                            @click="
                              chenageOpen5(
                                index,
                                cIndex,
                                dIndex,
                                eIndex,
                                fIndex
                              )
                            "
                            :class="[
                              'navigation-open-5',
                              { 'navigation-open-5-active': fItem.active },
                            ]"
                          >
                            <i class="fa fa-circle-o" aria-hidden="true"></i>
                            <span>{{ fItem.name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 页面关闭时导航内容 -->
        <div
          v-if="!openOrGate && !smallScreen"
          v-for="(item, index) in navigation"
          :key="item.name"
          class="navigation-content-close"
        >
          <div
            :class="[
              'navigation-open-1',
              { 'navigation-open-1-active': item.active },
            ]"
            @click="chenageOpen1(index)"
          >
            <i :class="item.icon" aria-hidden="true"></i>
          </div>

          <div class="navigation-content-abs">
            <div class="title-box">{{ item.name }}</div>

            <!-- 展开显示区域二级 -->
            <div v-if="item.subOption" class="navigation-open-2-box">
              <div v-for="(cItem, cIndex) in item.children" :key="cItem.name">
                <div
                  :class="[
                    'navigation-open-2',
                    { 'navigation-open-2-active': cItem.active },
                  ]"
                  @click="chenageOpen2(index, cIndex)"
                >
                  <i class="fa fa-circle-o" aria-hidden="true"></i>
                  <span>{{ cItem.name }}</span>

                  <i
                    v-if="cItem.subOption"
                    :class="[
                      'fa fa-angle-left',
                      { 'pull-rotate': cItem.arrow },
                    ]"
                    aria-hidden="true"
                  ></i>
                </div>

                <!-- 展开显示区域三级 -->
                <div
                  v-if="cItem.subOption"
                  :style="`height:${
                    cItem.active ? cItem.children.length * 30 + item.height3 : 0
                  }px`"
                  class="navigation-open-3-box"
                >
                  <div
                    v-for="(dItem, dIndex) in cItem.children"
                    :key="dItem.name"
                  >
                    <div
                      @click="chenageOpen3(index, cIndex, dIndex)"
                      :class="[
                        'navigation-open-3',
                        { 'navigation-open-3-active': dItem.active },
                      ]"
                    >
                      <i class="fa fa-circle-o" aria-hidden="true"></i>
                      <span>{{ dItem.name }}</span>
                      <i
                        v-if="dItem.subOption"
                        :class="[
                          'fa fa-angle-left',
                          { 'pull-rotate': dItem.arrow },
                        ]"
                        aria-hidden="true"
                      ></i>
                    </div>
                    <!-- 展开显示区域四级 -->
                    <div
                      v-if="dItem.subOption"
                      :style="`height:${
                        dItem.active
                          ? dItem.children.length * 30 + item.height4
                          : 0
                      }px`"
                      class="navigation-open-4-box"
                    >
                      <div
                        v-for="(eItem, eIndex) in dItem.children"
                        :key="eItem.name"
                      >
                        <div
                          :class="[
                            'navigation-open-4',
                            { 'navigation-open-4-active': eItem.active },
                          ]"
                          @click="chenageOpen4(index, cIndex, dIndex, eIndex)"
                        >
                          <i class="fa fa-circle-o" aria-hidden="true"></i>
                          <span>{{ eItem.name }}</span>
                          <i
                            v-if="eItem.subOption"
                            :class="[
                              'fa fa-angle-left',
                              { 'pull-rotate': eItem.arrow },
                            ]"
                            aria-hidden="true"
                          ></i>
                        </div>
                        <!-- 展开显示区域五级 -->
                        <div
                          v-if="eItem.subOption"
                          :style="`height:${
                            eItem.active ? eItem.children.length * 30 : 0
                          }px`"
                          class="navigation-open-5-box"
                        >
                          <div
                            v-for="(fItem, fIndex) in eItem.children"
                            :key="fItem.name"
                          >
                            <div
                              @click="
                                chenageOpen5(
                                  index,
                                  cIndex,
                                  dIndex,
                                  eIndex,
                                  fIndex
                                )
                              "
                              :class="[
                                'navigation-open-5',
                                { 'navigation-open-5-active': fItem.active },
                              ]"
                            >
                              <i class="fa fa-circle-o" aria-hidden="true"></i>
                              <span>{{ fItem.name }}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right" :style="`width:${width}px`">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import { setExit } from "@/api/user";
const clickOutside = {
  bind(el, binding) {
    // 在元素上绑定一个点击事件监听器
    el.clickOutsideEvent = function (event) {
      // 检查点击事件是否发生在元素的内部
      if (!(el === event.target || el.contains(event.target))) {
        // 如果点击事件发生在元素的外部，则触发指令绑定的方法
        binding.value(event);
      }
    };
    // 在文档上添加点击事件监听器
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    // 在元素上解除点击事件监听器
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};
import { navigation } from "./data-zn";
export default {
  // 监听,当路由发生变化的时候执行
  watch: {
    $route(to, from) {
      //
      this.getNewList();
    },
  },
  data() {
    return {
      openOrGate: true, //导航开关
      smallScreen: false, //是否小屏
      isUserIfo: false,
      timer: null,
      navigation: navigation,
      url: "https://hrms.baishulai.com",
      width: window.innerWidth - 230,
    };
  },
  directives: {
    "click-outside": clickOutside, // 注册自定义指令
  },
  methods: {
    // 导航栏开关
    changeSwitch() {
      this.openOrGate = !this.openOrGate;
      if (!this.openOrGate) {
        this.width = window.innerWidth - 50;
      } else {
        this.width = window.innerWidth - 230;
      }
    },
    // 监听屏幕宽度变化
    scrollToTop() {
      const width = document.body.clientWidth;
      if (width > 766 && !this.openOrGate && !this.smallScreen) {
        return;
      }

      if (width <= 766) {
        this.smallScreen = true;
      } else {
        this.smallScreen = false;
        this.openOrGate = true;
      }
    },
    //点击其它区域
    handleBlur(event) {
      this.isUserIfo = false;
    },
    //点击导航菜单第一级
    chenageOpen1(index) {
      if (!this.navigation[index].subOption) {
        // 已选中且没有子选项
        //赋值
        this.navigation[index].active = true;

        //修改其它
        this.navigation.forEach((cItem, cIndex, array) => {
          if (cIndex !== index) {
            cItem.active = false;
            cItem.height2 = 0;
            cItem.height3 = 0;
            cItem.height4 = 0;
            if (cItem.arrow) {
              cItem.arrow = false;
              cItem.children.forEach((dItem, dIndex, dArray) => {
                dItem.active = false;
                if (dItem.arrow && dItem.children) {
                  dItem.arrow = false;
                  dItem.children.forEach((eItem, eIndex, eArray) => {
                    eItem.active = false;
                    if (eItem.arrow) {
                      eItem.arrow = false;
                      eItem.children.forEach((fItem, fIndex, fArray) => {
                        fItem.active = false;
                        if (fItem.arrow) {
                          fItem.arrow = false;
                          fItem.children.forEach((gItem, gIndex, gArray) => {
                            gItem.active = false;
                            gArray[gIndex] = gItem;
                          });
                        }
                        fArray[fIndex] = fItem;
                      });
                    }
                    eArray[eIndex] = eItem;
                  });
                }
                dArray[dIndex] = dItem;
              });
            }
            array[cIndex] = cItem;
          }
        });

        if (this.navigation[index].path) {
          this.$router.push(this.navigation[index].path);
        }

        if (this.navigation[index].href) {
          location.href = this.url + this.navigation[index].href;
        }
      } else {
        let data = this.navigation;
        let navigation = this.navigation[index];
        data.forEach((_, value, Array) => {
          if (value === index) {
            Array[value].active = !Array[value].active;
            clearTimeout(this.timer);
            if (Array[value].active) {
              this.timer = setTimeout(() => {
                Array[value].arrow = true;
              }, 500);
              //  判断内部是否有展开项，没有则将height3赋值为0
              const hIndex = data[index].children.findIndex((hItem) => {
                return hItem.arrow;
              });
              if (hIndex === -1) {
                navigation.height2 = 0;
              }
            } else {
              Array[value].arrow = false;
            }
          } else {
            Array[value].arrow = false;
            Array[value].active = false;

            if (Array[value].children) {
              Array[value].children.forEach((fItem, fIndex, fArray) => {
                if (fArray[fIndex].active) {
                  fArray[fIndex].active = false;
                }
                if (fArray[fIndex].arrow) {
                  fArray[fIndex].arrow = false;
                }
                if (fArray[fIndex].children) {
                  fArray[fIndex].children.forEach((gItem, gIndex, gArray) => {
                    if (gArray[gIndex].active) {
                      gArray[gIndex].active = false;
                    }
                    if (gArray[gIndex].arrow) {
                      gArray[gIndex].arrow = false;
                    }
                    if (gArray[gIndex].children) {
                      gArray[gIndex].children.forEach(
                        (hItem, hIndex, hArray) => {
                          if (hArray[hIndex].active) {
                            hArray[hIndex].active = false;
                          }
                          if (hArray[hIndex].arrow) {
                            hArray[hIndex].arrow = false;
                          }
                          if (hArray[hIndex].children) {
                            hArray[hIndex].children.forEach(
                              (jItem, jIndex, jArray) => {
                                if (jArray[jIndex].active) {
                                  jArray[jIndex].active = false;
                                }
                                if (jArray[jIndex].arrow) {
                                  jArray[jIndex].arrow = false;
                                }
                              }
                            );
                          }
                        }
                      );
                    }
                  });
                }
              });
            }
          }
        });
      }
    },
    //点击导航菜单第二级
    chenageOpen2(index, cIndex) {
      if (!this.navigation[index].children[cIndex].subOption) {
        // 已选中且没有子选项

        //赋值
        this.navigation[index].children[cIndex].active = true;
        //修改其它
        this.navigation[index].children.forEach((dItem, dIndex, dArray) => {
          if (dIndex !== cIndex) {
            dItem.active = false;
            this.navigation[index].height2 = 0;
            this.navigation[index].height3 = 0;
            this.navigation[index].height4 = 0;
            if (dItem.arrow && dItem.children) {
              dItem.arrow = false;
              dItem.children.forEach((eItem, eIndex, eArray) => {
                eItem.active = false;
                if (eItem.arrow) {
                  eItem.arrow = false;
                  eItem.children.forEach((fItem, fIndex, fArray) => {
                    fItem.active = false;
                    if (fItem.arrow) {
                      fItem.arrow = false;
                      fItem.children.forEach((gItem, gIndex, gArray) => {
                        gItem.active = false;
                        gArray[gIndex] = gItem;
                      });
                    }
                    fArray[fIndex] = fItem;
                  });
                }
                eArray[eIndex] = eItem;
              });
            }
            dArray[dIndex] = dItem;
          }
        });

        if (this.navigation[index].children[cIndex].path) {
          if (this.navigation[index].children[cIndex].path === "/signin") {
            this.setExit();
            return;
          }
          this.$router.push(this.navigation[index].children[cIndex].path);
        }

        if (this.navigation[index].children[cIndex].href) {
          location.href =
            this.url + this.navigation[index].children[cIndex].href;
        }
      } else {
        let data = this.navigation[index].children;
        let navigation = this.navigation[index];
        data.forEach((_, value, Array) => {
          if (value === cIndex) {
            Array[value].active = !Array[value].active;
            clearTimeout(this.timer);
            if (Array[value].active) {
              this.timer = setTimeout(() => {
                Array[value].arrow = true;
              }, 500);

              //  判断内部是否有展开项，没有则将height3赋值为0
              const hIndex = data[cIndex].children.findIndex((hItem) => {
                return hItem.arrow;
              });
              if (hIndex === -1) {
                navigation.height3 = 0;
              }

              navigation.height2 =
                data[cIndex].children.length * 30 + navigation.height3;
            } else {
              Array[value].arrow = false;
              navigation.height2 = 0;
            }
          } else {
            Array[value].arrow = false;
            Array[value].active = false;
            if (Array[value].children) {
              Array[value].children.forEach((fItem, fIndex, fArray) => {
                if (fArray[fIndex].active) {
                  fArray[fIndex].active = false;
                }
                if (fArray[fIndex].arrow) {
                  fArray[fIndex].arrow = false;
                }
                if (fArray[fIndex].children) {
                  fArray[fIndex].children.forEach((gItem, gIndex, gArray) => {
                    if (gArray[gIndex].active) {
                      gArray[gIndex].active = false;
                    }
                    if (gArray[gIndex].arrow) {
                      gArray[gIndex].arrow = false;
                    }
                    if (gArray[gIndex].children) {
                      gArray[gIndex].children.forEach(
                        (hItem, hIndex, hArray) => {
                          if (hArray[hIndex].active) {
                            hArray[hIndex].active = false;
                          }
                          if (hArray[hIndex].arrow) {
                            hArray[hIndex].arrow = false;
                          }
                        }
                      );
                    }
                  });
                }
              });
            }
          }
        });

        // this.navigation[index].children = data;
        this.$forceUpdate();
      }
    },
    //点击导航菜单第三级
    chenageOpen3(index, cIndex, dIndex) {
      if (!this.navigation[index].children[cIndex].children[dIndex].children) {
        // 已选中且没有子选项

        //赋值
        this.navigation[index].children[cIndex].children[dIndex].active = true;
        //修改其它
        this.navigation[index].children[cIndex].children.forEach(
          (eItem, eIndex, eArray) => {
            if (eIndex !== dIndex) {
              eItem.active = false;
              this.navigation[index].height2 =
                this.navigation[index].height2 - this.navigation[index].height3;
              this.navigation[index].height3 = 0;
              this.navigation[index].height4 = 0;
              if (eItem.arrow) {
                eItem.arrow = false;
                eItem.children.forEach((fItem, fIndex, fArray) => {
                  fItem.active = false;
                  if (fItem.arrow) {
                    fItem.arrow = false;
                    fItem.children.forEach((gItem, gIndex, gArray) => {
                      gItem.active = false;
                      gArray[gIndex] = gItem;
                    });
                  }
                  fArray[fIndex] = fItem;
                });
              }
              eArray[eIndex] = eItem;
            }
          }
        );

        if (this.navigation[index].children[cIndex].children[dIndex].path) {
          this.$router.push(
            this.navigation[index].children[cIndex].children[dIndex].path
          );
        }
        if (this.navigation[index].children[cIndex].children[dIndex].href) {
          location.href =
            this.url +
            this.navigation[index].children[cIndex].children[dIndex].href;
        }
      } else {
        let data = this.navigation[index].children[cIndex].children;
        let navigation = this.navigation[index];
        data.forEach((_, value, Array) => {
          if (value === dIndex) {
            Array[value].active = !Array[value].active;
            const height3 = navigation.height3;
            clearTimeout(this.timer);
            if (Array[value].active) {
              this.timer = setTimeout(() => {
                Array[value].arrow = true;
              }, 500);

              //  判断内部是否有展开项，没有则将height4赋值为0
              const hIndex = data[dIndex].children.findIndex((hItem) => {
                return hItem.arrow;
              });
              if (hIndex === -1) {
                navigation.height4 = 0;
              }
              navigation.height3 =
                data[dIndex].children.length * 30 + navigation.height4;
              navigation.height2 =
                navigation.height3 + navigation.height2 - height3;
            } else {
              Array[value].arrow = false;
              navigation.height3 = 0;
              navigation.height2 =
                navigation.height3 + navigation.height2 - height3;
            }
          } else {
            Array[value].arrow = false;
            Array[value].active = false;
            if (Array[value].children) {
              Array[value].children.forEach((fItem, fIndex, fArray) => {
                if (fArray[fIndex].active) {
                  fArray[fIndex].active = false;
                }
                if (fArray[fIndex].arrow) {
                  fArray[fIndex].arrow = false;
                }
                if (fArray[fIndex].children) {
                  fArray[fIndex].children.forEach((gItem, gIndex, gArray) => {
                    if (gArray[gIndex].active) {
                      gArray[gIndex].active = false;
                    }
                    if (gArray[gIndex].arrow) {
                      gArray[gIndex].arrow = false;
                    }
                  });
                }
              });
            }
          }
        });
        this.$forceUpdate();
      }
    },
    //点击导航菜单第四级
    chenageOpen4(index, cIndex, dIndex, eIndex) {
      if (
        !this.navigation[index].children[cIndex].children[dIndex].children[
          eIndex
        ].children
      ) {
        // 已选中且没有子选项

        //赋值
        this.navigation[index].children[cIndex].children[dIndex].children[
          eIndex
        ].active = true;
        //修改其它
        this.navigation[index].children[cIndex].children[
          dIndex
        ].children.forEach((fItem, fIndex, fArray) => {
          if (fIndex !== eIndex) {
            fItem.active = false;
            if (fItem.arrow) {
              fItem.arrow = false;
              this.navigation[index].height2 =
                this.navigation[index].height2 - this.navigation[index].height4;
              this.navigation[index].height3 =
                this.navigation[index].height3 - this.navigation[index].height4;
              this.navigation[index].height4 = 0;
              fItem.children.forEach((gItem, gIndex, gArray) => {
                gItem.active = false;
                gArray[gIndex] = gItem;
              });
            }
            fArray[fIndex] = fItem;
          }
        });

        const navigation =
          this.navigation[index].children[cIndex].children[dIndex].children[
            eIndex
          ];
        if (navigation.path) {
          this.$router.push(navigation.path);
        }
        if (navigation.href) {
          location.href = this.url + navigation.href;
        }
      } else {
        let data =
          this.navigation[index].children[cIndex].children[dIndex].children;
        let navigation = this.navigation[index];
        data.forEach((_, value, Array) => {
          if (value === eIndex) {
            Array[value].active = !Array[value].active;
            const height4 = navigation.height4;
            const height3 = navigation.height3;
            clearTimeout(this.timer);
            if (Array[value].active) {
              this.timer = setTimeout(() => {
                Array[value].arrow = true;
              }, 500);
              navigation.height4 = data[eIndex].children.length * 30;
              navigation.height3 =
                navigation.height4 + navigation.height3 - height4;
              navigation.height2 =
                navigation.height2 - height3 + navigation.height3;
            } else {
              Array[value].arrow = false;
              navigation.height4 = 0;
              navigation.height3 =
                navigation.height4 + navigation.height3 - height4;
              navigation.height2 =
                navigation.height2 - height3 + navigation.height3;
            }
          } else {
            Array[value].arrow = false;
            Array[value].active = false;
          }
        });
        this.$forceUpdate();
      }
    },
    //点击导航菜单第五级
    chenageOpen5(index, cIndex, dIndex, eIndex, fIndex) {
      //赋值
      this.navigation[index].children[cIndex].children[dIndex].children[
        eIndex
      ].children[fIndex].active = true;

      const navigation =
        this.navigation[index].children[cIndex].children[dIndex].children[
          eIndex
        ].children[fIndex];
      if (navigation.path) {
        this.$router.push(navigation.path);
      }
      if (navigation.href) {
        location.href = this.url + navigation.href;
      }
      this.navigation[index].children[cIndex].children[dIndex].children[
        eIndex
      ].children.forEach((gItem, gIndex, gArray) => {
        if (gIndex !== fIndex) {
          gItem.active = false;
        }
        gArray[gIndex] = gItem;
      });
    },
    ziliao() {
      location.href =
        this.url + "/pages/SYSTEM/AdminEdit.aspx?type=edit&userid=1";
    },
    goYuangong() {
      this.$router.push("/ordinary");
    },
    setExit() {
      setExit().then(() => {
        this.$router.push("/signin");
      });
    },
    getNewList() {
      let navigationNew = JSON.parse(JSON.stringify(navigation));
      switch (this.$route.path) {
        case "/module/home": //工作台
          this.navigation = navigationNew;
          this.navigation[0].active = true;
          break;
        case "/module/message": //消息设置
          this.navigation = navigationNew;
          this.navigation[5].active = true;
          break;
        case "/module/procedure": //审批管理-流程管理
          this.navigation = navigationNew;
          this.navigation[5].active = true;
          this.navigation[5].arrow = true;
          this.navigation[5].children[0].active = true;
          break;
        case "/module/examine1": //审批管理-我的审批-待办事项
          const examine1 = 5;
          this.navigation = navigationNew;
          this.navigation[examine1].active = true;
          this.navigation[examine1].arrow = true;
          this.navigation[examine1].children[1].active = true;
          this.navigation[examine1].children[1].active = true;
          this.navigation[examine1].children[1].arrow = true;
          this.navigation[examine1].children[1].children[0].active = true;
          this.navigation[examine1].height2 = 120;
          break;
        case "/module/examine2": //审批管理-我的审批-已办事项
          const examine2 = 5;
          this.navigation = navigationNew;
          this.navigation[examine2].active = true;
          this.navigation[examine2].arrow = true;
          this.navigation[examine2].children[1].active = true;
          this.navigation[examine2].children[1].active = true;
          this.navigation[examine2].children[1].arrow = true;
          this.navigation[examine2].children[1].children[1].active = true;
          this.navigation[examine2].height2 = 120;
          break;
        case "/module/examine3": //审批管理-我的审批-跟踪事项
          const examine3 = 5;
          this.navigation = navigationNew;
          this.navigation[examine3].active = true;
          this.navigation[examine3].arrow = true;
          this.navigation[examine3].children[1].active = true;
          this.navigation[examine3].children[1].active = true;
          this.navigation[examine3].children[1].arrow = true;
          this.navigation[examine3].children[1].children[2].active = true;
          this.navigation[examine3].height2 = 120;
          break;
        case "/module/examine4": //审批管理-我的审批-跟踪事项
          const examine4 = 5;
          this.navigation = navigationNew;
          this.navigation[examine4].active = true;
          this.navigation[examine4].arrow = true;
          this.navigation[examine4].children[1].active = true;
          this.navigation[examine4].children[1].active = true;
          this.navigation[examine4].children[1].arrow = true;
          this.navigation[examine4].children[1].children[3].active = true;
          this.navigation[examine4].height2 = 120;
          break;
        case "/module/consult": //审批管理-我的审批-跟踪事项
          this.navigation = navigationNew;
          this.navigation[5].active = true;
          this.navigation[5].arrow = true;
          this.navigation[5].children[2].active = true;
          break;
        case "/module/rights": //组织机构-权限配置
          this.navigation = navigationNew;
          this.navigation[6].active = true;
          this.navigation[6].arrow = true;
          this.navigation[6].children[3].active = true;
          break;
        case "/module/roles": //组织机构-角色管理
          this.navigation = navigationNew;
          this.navigation[6].active = true;
          this.navigation[6].arrow = true;
          this.navigation[6].children[4].active = true;
          break;
        case "/module/system": //组织机构-系统模块
          this.navigation = navigationNew;
          this.navigation[6].active = true;
          this.navigation[6].arrow = true;
          this.navigation[6].children[5].active = true;
          break;
        case "/module/project": //组织机构-组织机构-项目部
          const project = 6;
          this.navigation = navigationNew;
          this.navigation[project].active = true;
          this.navigation[project].arrow = true;
          this.navigation[project].children[0].active = true;
          this.navigation[project].children[0].arrow = true;
          this.navigation[project].children[0].children[0].active = true;

          this.navigation[project].height2 = 120;
          break;
        case "/module/company": //组织机构-组织机构-单位
          const company = 6;
          this.navigation = navigationNew;
          this.navigation[company].active = true;
          this.navigation[company].arrow = true;
          this.navigation[company].children[0].active = true;
          this.navigation[company].children[0].arrow = true;
          this.navigation[company].children[0].children[1].active = true;

          this.navigation[company].height2 = 120;
          break;
        case "/module/department": //组织机构-组织机构-部门
          const department = 6;
          this.navigation = navigationNew;
          this.navigation[department].active = true;
          this.navigation[department].arrow = true;
          this.navigation[department].children[0].active = true;
          this.navigation[department].children[0].arrow = true;
          this.navigation[department].children[0].children[2].active = true;

          this.navigation[department].height2 = 120;
          break;
        case "/module/organization": //组织机构-组织机构-组织关系
          const organization = 6;
          this.navigation = navigationNew;
          this.navigation[organization].active = true;
          this.navigation[organization].arrow = true;
          this.navigation[organization].children[0].active = true;
          this.navigation[organization].children[0].arrow = true;
          this.navigation[organization].children[0].children[3].active = true;

          this.navigation[organization].height2 = 120;
          break;

        case "/module/position": //组织机构-岗位职务
          const position = 6;
          this.navigation = navigationNew;
          this.navigation[position].active = true;
          this.navigation[position].arrow = true;
          this.navigation[position].children[2].active = true;
          break;
        case "/module/costCenter": //组织机构-成本中心
          const costCenter = 6;
          this.navigation = navigationNew;
          this.navigation[costCenter].active = true;
          this.navigation[costCenter].arrow = true;
          this.navigation[costCenter].children[1].active = true;
          break;
        case "/module/manpower": //人事管理-人力需求计划
          this.navigation = navigationNew;
          this.navigation[3].active = true;
          this.navigation[3].arrow = true;
          this.navigation[3].children[0].active = true;
          this.navigation[3].children[0].arrow = true;
          this.navigation[3].children[0].children[1].active = true;
          this.navigation[3].height2 = 60;
          break;
        case "/module/outsourcingApproval": //人事管理-外包单位引入
          this.navigation = navigationNew;
          this.navigation[3].active = true;
          this.navigation[3].arrow = true;
          this.navigation[3].children[8].active = true;
          this.navigation[3].children[8].arrow = true;
          this.navigation[3].children[8].children[0].active = true;
          this.navigation[3].height2 = 60;
          break;
        case "/module/oufm": //人事管理-外包单位引入
          this.navigation = navigationNew;
          this.navigation[3].active = true;
          this.navigation[3].arrow = true;
          this.navigation[3].children[8].active = true;
          this.navigation[3].children[8].arrow = true;
          this.navigation[3].children[8].children[1].active = true;
          this.navigation[3].height2 = 60;
          break;
        case "/module/oufmTable": //人事管理-外包单位引入
          this.navigation = navigationNew;
          this.navigation[3].active = true;
          this.navigation[3].arrow = true;
          this.navigation[3].children[8].active = true;
          this.navigation[3].children[8].arrow = true;
          this.navigation[3].children[8].children[1].active = true;
          this.navigation[3].height2 = 60;
          break;
        case "/module/oInformation": //人事管理-订单管理-外包人员单价
          this.navigation = navigationNew;
          this.navigation[3].active = true;
          this.navigation[3].arrow = true;
          this.navigation[3].children[9].active = true;
          this.navigation[3].children[9].arrow = true;
          this.navigation[3].children[9].children[0].active = true;
          this.navigation[3].height2 = 90;
          break;
        case "/module/ljsoo": //人事管理-订单管理-人员订单信息签发
          this.navigation = navigationNew;
          this.navigation[3].active = true;
          this.navigation[3].arrow = true;
          this.navigation[3].children[9].active = true;
          this.navigation[3].children[9].arrow = true;
          this.navigation[3].children[9].children[1].active = true;
          this.navigation[3].height2 = 90;
          break;
        case "/module/ljsoosjk": //人事管理-订单管理-订单数据库
          this.navigation = navigationNew;
          this.navigation[3].active = true;
          this.navigation[3].arrow = true;
          this.navigation[3].children[9].active = true;
          this.navigation[3].children[9].arrow = true;
          this.navigation[3].children[9].children[2].active = true;
          this.navigation[3].height2 = 90;
          break;
        case "/module/monthlyCredit": //人事管理-结算管理-1
          this.navigation = navigationNew;
          this.navigation[3].active = true;
          this.navigation[3].arrow = true;
          this.navigation[3].children[10].active = true;
          this.navigation[3].children[10].arrow = true;
          this.navigation[3].children[10].children[0].active = true;
          this.navigation[3].height2 = 90;
          break;
        case "/module/monthlyCreditDetails": //人事管理-结算管理-1-1
          this.navigation = navigationNew;
          this.navigation[3].active = true;
          this.navigation[3].arrow = true;
          this.navigation[3].children[10].active = true;
          this.navigation[3].children[10].arrow = true;
          this.navigation[3].children[10].children[0].active = true;
          this.navigation[3].height2 = 90;
          break;
        case "/module/monthlyCreditManagement": //人事管理-结算管理-2
          this.navigation = navigationNew;
          this.navigation[3].active = true;
          this.navigation[3].arrow = true;
          this.navigation[3].children[10].active = true;
          this.navigation[3].children[10].arrow = true;
          this.navigation[3].children[10].children[1].active = true;
          this.navigation[3].height2 = 90;
          break;
        case "/module/outsourcingFee": //人事管理-结算管理-3
          this.navigation = navigationNew;
          this.navigation[3].active = true;
          this.navigation[3].arrow = true;
          this.navigation[3].children[10].active = true;
          this.navigation[3].children[10].arrow = true;
          this.navigation[3].children[10].children[2].active = true;
          this.navigation[3].height2 = 90;
          break;
        case "/module/leave": //人事管理-请假
          this.navigation = navigationNew;
          this.navigation[3].active = true;
          this.navigation[3].arrow = true;
          this.navigation[3].children[11].active = true;
          this.navigation[3].children[11].arrow = true;

          break;
        case "/module/overtime": //人事管理-加班
          this.navigation = navigationNew;
          this.navigation[3].active = true;
          this.navigation[3].arrow = true;
          this.navigation[3].children[12].active = true;
          this.navigation[3].children[12].arrow = true;
          break;
        case "/module/vacation": //人事管理-休假
          // this.navigation = navigationNew;
          // this.navigation[3].active = true;
          // this.navigation[3].arrow = true;
          // this.navigation[3].children[11].active = true;
          // this.navigation[3].children[11].arrow = true;
          break;
        case "/module/cost1": //费用管理-沙化费用
          const cost1 = 7;
          this.navigation = navigationNew;
          this.navigation[cost1].active = true;
          this.navigation[cost1].arrow = true;
          this.navigation[cost1].children[1].active = true;
          break;
        case "/module/cost2": //费用管理-沙特籍培训费用
          const cost2 = 7;
          this.navigation = navigationNew;
          this.navigation[cost2].active = true;
          this.navigation[cost2].arrow = true;
          this.navigation[cost2].children[2].active = true;
          break;
        case "/module/cost3": //费用管理-HRDF返还费用
          const cost3 = 7;
          this.navigation = navigationNew;
          this.navigation[cost3].active = true;
          this.navigation[cost3].arrow = true;
          this.navigation[cost3].children[3].active = true;
          break;
        case "/module/costChilden1": //费用管理-费用管理-费用处理
          const costChilden1 = 7;
          this.navigation = navigationNew;
          this.navigation[costChilden1].active = true;
          this.navigation[costChilden1].arrow = true;
          this.navigation[costChilden1].children[0].active = true;
          this.navigation[costChilden1].children[0].arrow = true;
          this.navigation[costChilden1].children[0].children[0].active = true;
          this.navigation[costChilden1].height2 = 30;
          break;
        case "/module/costChilden2": //费用管理-费用管理-沙特籍培训费用
          const costChilden2 = 7;
          this.navigation = navigationNew;
          this.navigation[costChilden2].active = true;
          this.navigation[costChilden2].arrow = true;
          this.navigation[costChilden2].children[0].active = true;
          this.navigation[costChilden2].children[0].arrow = true;
          this.navigation[costChilden2].children[0].children[1].active = true;
          this.navigation[costChilden2].height2 = 90;
          break;
        case "/module/costChilden3": //费用管理-费用管理-HRDF返还费用
          const costChilden3 = 7;
          this.navigation = navigationNew;
          this.navigation[costChilden3].active = true;
          this.navigation[costChilden3].arrow = true;
          this.navigation[costChilden3].children[0].active = true;
          this.navigation[costChilden3].children[0].arrow = true;
          this.navigation[costChilden3].children[0].children[2].active = true;
          this.navigation[costChilden3].height2 = 90;
          break;
        case "/module/journal": //系统设置-系统日志
          const journal = 10;
          this.navigation = navigationNew;
          this.navigation[journal].active = true;
          this.navigation[journal].arrow = true;
          this.navigation[journal].children[0].active = true;
          break;
      }
      window.addEventListener("resize", this.scrollToTop, true);
      this.scrollToTop();
    },
    onResize() {
      // const rect=document.getBoundingClientRect()
      // this.$refs.navigation
      console.log("111", this.$refs.navigation.clientWidth);
      console.log("1111屏幕发生变化", window.innerWidth);
      this.width = window.innerWidth - this.$refs.navigation.clientWidth;
    },
  },
  mounted() {
    // 刷新页面
    this.getNewList();

    if (!this.$store.state.userInfo) {
      this.$store.commit(
        "changeUserInfo",
        JSON.parse(localStorage.getItem("userInfo"))
      );
    }
    // this.onResize();
  },
  beforeMount() {
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.scrollToTop, true);
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.main {
  max-width: 100vw;
  overflow-x: hidden;
  //   顶部一栏
  .main-top {
    min-height: 50px;
    background-color: #3c8dbc;
    display: flex;
    .left {
      flex-shrink: 0;
      width: 230px;
      height: 50px;
      background-color: #367fa9;
      cursor: pointer;
      color: #fff;
      line-height: 50px;
      text-align: center;
      font-size: 20px;
      transition: width 0.3s ease-in-out;
      overflow: hidden;
      b {
        font-weight: 700;
      }
    }
    .left:hover {
      background-color: #357ca5;
    }
    .left-close {
      width: 50px;
      .sne {
        font-size: 18px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        // margin-bottom: 5px;
        font-weight: 700;
      }
    }
    .right {
      display: flex;
      justify-content: space-between;
      transition: width 0.3s ease-in-out;
      position: relative;
      width: 100%;
      .icon {
        flex-shrink: 0;
        width: 12px;
        display: flex;
        padding: 15px 15px;
        color: #fff;
        cursor: pointer;
        .fa {
          font-size: 14px;
          line-height: 1.42857143;
        }
      }
      .icon:hover {
        background-color: #367fa9;
      }
      .right-box {
        display: flex;
        height: 100%;
        .button-fa {
          display: flex;
          align-items: center;
          height: 100%;
          button {
            height: 100%;
            line-height: 199%;
            padding: 0;
            border: none;
            align-items: center;
            background-color: #3c8dbc;
            color: #fff;
            cursor: pointer;
            padding: 0 12px;
          }
        }
        .button-fa:hover {
          button {
            background-color: #367fa9;
          }
        }
      }
      .user-box {
        padding: 0 15px;
        display: flex;
        align-items: center;
        .user-img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          margin-right: 10px;
          margin-top: 1px;
        }
        .user-name {
          font-size: 14px;
          color: #fff;
          font-family: "Microsoft YaHei";
          margin-bottom: 0.5px;
        }
      }
      .user-box:hover {
        background-color: #367fa9;
      }
      .user-active {
        background-color: #367fa9;
      }
      .pop-frame {
        position: absolute;
        right: 0;
        width: 278.4px;
        top: 50px;
        padding: 1px;
        background-color: #fff;
        z-index: 100;
        .user-header {
          height: 155px;
          padding: 10px;
          text-align: center;
          background-color: #3c8dbc;
          overflow: hidden;
          .circle-box {
            width: 85px;
            height: 85px;
            border-radius: 44px;
            border: 3px solid rgba(255, 255, 255, 0.2);
            overflow: hidden;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            .img-circle {
              width: 100%;
              height: 100%;
            }
          }
          p {
            color: rgba(255, 255, 255, 0.8);
            font-size: 17px;
            margin-top: 10px;
            font-family: "Microsoft YaHei";
            line-height: 1.42857143;
            small {
              display: block;
              font-size: 12px;
              line-height: 1.42857143;
            }
          }
        }
        .user-footer {
          background-color: #f9f9f9;
          padding: 10px;
          .pull-left {
            float: left !important;
            font-size: 14px;
            text-align: left;
            list-style: none;
            font-family: "Microsoft YaHei";
            line-height: 1.42857143;
            color: #666666;
            background-color: #f4f4f4;
            border: 1px solid #ddd;
            padding: 6px 12px;
            text-align: center;
            cursor: pointer;
          }
          .pull-right {
            float: right !important;
            font-size: 14px;
            text-align: left;
            list-style: none;
            font-family: "Microsoft YaHei";
            line-height: 1.42857143;
            color: #666666;
            background-color: #f4f4f4;
            border: 1px solid #ddd;
            padding: 6px 12px;
            text-align: center;
            cursor: pointer;
          }
        }
        .user-footer::after {
          content: "";
          display: table;
          clear: both;
        }
      }
    }
  }

  //主体内容区域
  .main-content {
    display: flex;
    height: auto;
    max-width: 100vw;
    overflow: auto;
    // min-height: 1000px;
    min-height: calc(100vh - 50px);
    .navigation {
      width: 230px;
      background-color: #222d32;
      flex-shrink: 0;
      transition: width 0.3s ease-in-out;
      .input-box {
        padding: 10px;
        width: calc(100% - 22px);
        position: relative;
        overflow: hidden;
        .form-control {
          outline: none;
          border: none;
          background-color: #374850;
          border: 1px solid transparent;
          width: 156px;
          border-radius: 4px;
          padding: 7.5px 42px 7.5px 12px;
          font-size: 14px;
          line-height: 1.42857143;
          font-family: inherit;
          color: #796666;
          opacity: 1;
        }
        .search {
          position: absolute;
          width: 38.6px;
          height: 36.6px;
          line-height: 36.6px;
          text-align: center;
          right: 10px;
          color: #796666;
          font-size: 14px;
          opacity: 1;
          .fa {
            color: #999;
          }
        }
        .form-control:focus {
          background-color: #fff;
        }
      }
      .main-navigation {
        height: 37.15px;
        line-height: 37.15px;
        width: calc(100% - 15px);
        background-color: #1a2226;
        font-family: "Microsoft YaHei";
        font-size: 12px;
        color: #4b646f;
        padding-left: 15px;
      }
      .navigation-content {
        cursor: pointer;
        width: 100%;
        .navigation-open-1 {
          width: 100%;
          height: 44px;
          color: #b8c7ce;
          font-size: 14px;
          line-height: 44px;
          font-weight: 400;
          font-family: "Microsoft YaHei";
          border-left: 3px solid transparent;
          position: relative;
          box-sizing: border-box;
          .fa {
            width: 18px;
            font-size: 14px;
            margin-left: 13px;
            box-sizing: border-box;
          }
          .ion {
            width: 18px;
            font-size: 14px;
            margin-left: 13px;
            box-sizing: border-box;
          }
          .fa-angle-left {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 0;
            width: auto;
          }
          .pull-rotate {
            transform: translateY(-50%) rotate(-90deg);
          }
        }
        .navigation-open-1-active {
          border-left-color: #3c8dbc;
          color: #fff;
          background-color: #1a2226;
        }
        .navigation-open-1:hover {
          border-left-color: #3c8dbc;
          color: #fff;
          background-color: #1a2226;
        }
        .navigation-open-2-box {
          overflow: hidden;
          height: 0;
          transition: height 0.5s;
          background-color: #2c3b41;
          .navigation-open-2 {
            width: 230px;
            height: 30px;
            color: #b8c7ce;
            font-size: 14px;
            line-height: 30px;
            font-weight: 400;
            font-family: "Microsoft YaHei";
            border-left: 3px solid transparent;
            position: relative;
            border: none;
            .fa {
              width: 20px;
              font-size: 14px;
              margin-left: 18px;
              box-sizing: border-box;
            }
            .fa-angle-left {
              position: absolute;
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
              width: auto;
            }
            .pull-rotate {
              transform: translateY(-50%) rotate(-90deg);
            }
          }
          .navigation-open-2:hover {
            color: #fff;
          }
          .navigation-open-2-active {
            color: #fff;
          }
        }
        .navigation-open-3-box {
          transition: height 0.5s;
          background-color: #2c3b41;
          overflow: hidden;
          height: 0;
          .navigation-open-3 {
            width: 230px;
            height: 30px;
            color: #b8c7ce;
            font-size: 14px;
            line-height: 30px;
            font-weight: 400;
            font-family: "Microsoft YaHei";
            border-left: 3px solid transparent;
            border: none;
            position: relative;
            .fa {
              width: 20px;
              font-size: 14px;
              margin-left: 36px;
              box-sizing: border-box;
            }
            .fa-angle-left {
              position: absolute;
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
              width: auto;
            }
            .pull-rotate {
              transform: translateY(-50%) rotate(-90deg);
            }
          }
          .navigation-open-3:hover {
            color: #fff;
          }
          .navigation-open-3-active {
            color: #fff;
          }
        }
        .navigation-open-4-box {
          transition: height 0.5s;
          background-color: #2c3b41;
          overflow: hidden;
          height: 0;
          .navigation-open-4 {
            width: calc(100% - 3px);
            height: 30px;
            color: #b8c7ce;
            font-size: 14px;
            line-height: 30px;
            font-weight: 400;
            font-family: "Microsoft YaHei";
            border-left: 3px solid transparent;
            position: relative;
            .fa {
              width: 20px;
              font-size: 14px;
              margin-left: 54px;
              box-sizing: border-box;
            }
            .fa-angle-left {
              position: absolute;
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
              width: auto;
            }
            .pull-rotate {
              transform: translateY(-50%) rotate(-90deg);
            }
          }
          .navigation-open-4:hover {
            color: #fff;
          }
          .navigation-open-4-active {
            color: #fff;
          }
        }
        .navigation-open-5-box {
          transition: height 0.5s;
          background-color: #2c3b41;
          overflow: hidden;
          height: 0;
          .navigation-open-5 {
            width: 100%;
            height: 30px;
            color: #b8c7ce;
            font-size: 14px;
            line-height: 30px;
            font-weight: 400;
            font-family: "Microsoft YaHei";
            border-left: 3px solid transparent;
            .fa {
              width: 20px;
              font-size: 14px;
              margin-left: 72px;
              box-sizing: border-box;
            }
            .fa-angle-left {
              line-height: 30px;
              margin-right: 10px;
              // margin-left: 0;
              // width: auto;
            }
          }
          .navigation-open-5:hover {
            color: #fff;
          }
          .navigation-open-5-active {
            color: #fff;
          }
        }
      }

      .navigation-content-close {
        cursor: pointer;
        width: 100%;
        position: relative;
        .navigation-open-1 {
          width: 100%;
          height: 44px;
          color: #b8c7ce;
          font-size: 14px;
          line-height: 44px;
          font-weight: 400;
          font-family: "Microsoft YaHei";
          border-left: 3px solid transparent;
          position: relative;
          box-sizing: border-box;
          .fa {
            width: 18px;
            font-size: 14px;
            margin-left: 14px;
            box-sizing: border-box;
          }
          .ion {
            width: 18px;
            font-size: 14px;
            margin-left: 14px;
            box-sizing: border-box;
          }
          .fa-angle-left {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 0;
            width: auto;
          }
          .pull-rotate {
            transform: translateY(-50%) rotate(-90deg);
          }
        }
        .navigation-open-1-active {
          border-left-color: #3c8dbc;
          color: #fff;
          background-color: #1a2226;
        }
        .navigation-open-1:hover {
          border-left-color: #3c8dbc;
          color: #fff;
          background-color: #1a2226;
        }
        .navigation-content-abs {
          position: absolute;
          top: 0;
          left: 50px;
          width: 180px;
          display: none;
          white-space: nowrap;
          z-index: 100;
          .title-box {
            height: 44px;
            line-height: 44px;
            width: calc(100% - 20px);
            border-radius: 0 3px 3px 0;
            padding-left: 20px;
            box-sizing: content-box;
            background-color: #1a2226;
            color: #fff;
          }
          .navigation-open-2-box {
            height: auto;
            transition: height 0.5s;
            background-color: #2c3b41;
            width: 180px;
            border-radius: 0 3px 3px 0;
            .navigation-open-2 {
              width: 180px;
              height: 30px;
              color: #b8c7ce;
              font-size: 14px;
              line-height: 30px;
              font-weight: 400;
              font-family: "Microsoft YaHei";
              border-left: 3px solid transparent;
              position: relative;
              border: none;
              .fa {
                width: 20px;
                font-size: 14px;
                margin-left: 18px;
                box-sizing: border-box;
              }
              .fa-angle-left {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: auto;
              }
              .pull-rotate {
                transform: translateY(-50%) rotate(-90deg);
              }
            }
            .navigation-open-2:hover {
              color: #fff;
            }
            .navigation-open-2-active {
              color: #fff;
            }
          }
          .navigation-open-3-box {
            transition: height 0.5s;
            background-color: #2c3b41;
            overflow: hidden;
            height: 0;
            .navigation-open-3 {
              width: 230px;
              height: 30px;
              color: #b8c7ce;
              font-size: 14px;
              line-height: 30px;
              font-weight: 400;
              font-family: "Microsoft YaHei";
              border-left: 3px solid transparent;
              border: none;
              position: relative;
              .fa {
                width: 20px;
                font-size: 14px;
                margin-left: 36px;
                box-sizing: border-box;
              }
              .fa-angle-left {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: auto;
              }
              .pull-rotate {
                transform: translateY(-50%) rotate(-90deg);
              }
            }
            .navigation-open-3:hover {
              color: #fff;
            }
            .navigation-open-3-active {
              color: #fff;
            }
          }
          .navigation-open-4-box {
            transition: height 0.5s;
            background-color: #2c3b41;
            overflow: hidden;
            height: 0;
            .navigation-open-4 {
              width: calc(100% - 3px);
              height: 30px;
              color: #b8c7ce;
              font-size: 14px;
              line-height: 30px;
              font-weight: 400;
              font-family: "Microsoft YaHei";
              border-left: 3px solid transparent;
              position: relative;
              .fa {
                width: 20px;
                font-size: 14px;
                margin-left: 54px;
                box-sizing: border-box;
              }
              .fa-angle-left {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: auto;
              }
              .pull-rotate {
                transform: translateY(-50%) rotate(-90deg);
              }
            }
            .navigation-open-4:hover {
              color: #fff;
            }
            .navigation-open-4-active {
              color: #fff;
            }
          }
          .navigation-open-5-box {
            transition: height 0.5s;
            background-color: #2c3b41;
            overflow: hidden;
            height: 0;
            .navigation-open-5 {
              width: 100%;
              height: 30px;
              color: #b8c7ce;
              font-size: 14px;
              line-height: 30px;
              font-weight: 400;
              font-family: "Microsoft YaHei";
              border-left: 3px solid transparent;
              .fa {
                width: 20px;
                font-size: 14px;
                margin-left: 72px;
                box-sizing: border-box;
              }
              .fa-angle-left {
                line-height: 30px;
                margin-right: 10px;
                // margin-left: 0;
                // width: auto;
              }
            }
            .navigation-open-5:hover {
              color: #fff;
            }
            .navigation-open-5-active {
              color: #fff;
            }
          }
        }
      }
      .navigation-content-close:hover {
        .navigation-content-abs {
          display: block;
          z-index: 100;
        }
      }
    }
    .navigation-close {
      width: 50px;
    }
    .navigation-none {
      width: 0;
    }
    .right {
      flex-shrink: 0;
      width: 100%;
    }
  }

  .modal {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }
}
</style>

<style scoped>
/* Chrome */
.form-control::-webkit-input-placeholder {
  color: #999999;
}
/* Firefox */
.form-control::-moz-placeholder {
  color: #999999;
  opacity: 1;
}
/* IE */
.form-control:-ms-input-placeholder {
  color: #999999;
}
</style>



<style lang="scss" scoped>
// 媒体查询
@media only screen and (max-width: 767px) {
  .main {
    .main-top {
      flex-wrap: wrap;
      .left {
        width: 100%;
      }
      .right {
        width: 100%;
        .user-box {
          padding: 10px 15px;
          .user-img {
            margin-right: 0;
            position: relative;
            top: -2.6px;
          }
          .user-name {
            display: none;
          }
        }
        .pop-frame {
          right: 5%;
          top: 51px;
        }
      }
    }
  }
}
</style>
