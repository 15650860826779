import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo: null, //用户信息
    userPower: null, //用户权限
  },
  getters: {},
  mutations: {
    //用户信息
    changeUserInfo(state, value) {
      state.userInfo = value;
    },
    //用户权限
    changeUserPower(state, value) {
      state.userPower = value;
    },
  },
  actions: {},
  modules: {},
});
