import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/components/Navigation/index.vue";
import Layout2 from "@/components/Navigation2/index.vue";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/signin",
  },
  {
    path: "/module",
    name: "module",
    component: Layout,
    redirect: "/module/home",
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/views/module/home"),
        meta: { title: "工作平台" },
      },
      {
        path: "message",
        component: () => import("@/views/module/message"),
        meta: { title: "消息管理" },
      },
      {
        path: "procedure",
        component: () => import("@/views/module/procedure"),
        meta: { title: "流程管理" },
      },
      {
        path: "examine1",
        component: () => import("@/views/module/examine1"),
        meta: { title: "待办事项" },
      },
      {
        path: "examine2",
        component: () => import("@/views/module/examine2"),
        meta: { title: "已办事项" },
      },
      {
        path: "examine3",
        component: () => import("@/views/module/examine3"),
        meta: { title: "跟踪事项" },
      },
      {
        path: "examine4",
        component: () => import("@/views/module/examine4"),
        meta: { title: "已结束" },
      },
      {
        path: "consult",
        component: () => import("@/views/module/consult"),
        meta: { title: "待我查阅" },
      },
      {
        path: "rights",
        component: () => import("@/views/module/rights"),
        meta: { title: "权限配置" },
      },
      {
        path: "roles",
        component: () => import("@/views/module/roles"),
        meta: { title: "角色管理" },
      },
      {
        path: "system",
        component: () => import("@/views/module/system"),
        meta: { title: "系统模块" },
      },
      {
        path: "project",
        component: () => import("@/views/module/project"),
        meta: { title: "项目部" },
      },
      {
        path: "company",
        component: () => import("@/views/module/company"),
        meta: { title: "单位" },
      },
      {
        path: "department",
        component: () => import("@/views/module/department"),
        meta: { title: "部门" },
      },
      {
        path: "organization",
        component: () => import("@/views/module/organization"),
        meta: { title: "组织关系" },
      },
      {
        path: "position",
        component: () => import("@/views/module/position"),
        meta: { title: "岗位职务" },
      },
      {
        path: "costCenter",
        component: () => import("@/views/module/costCenter"),
        meta: { title: "成本中心" },
      },
      {
        path: "manpower",
        component: () => import("@/views/module/manpower"),
        meta: { title: "人力需求计划" },
      },
      {
        path: "outsourcingApproval",
        component: () => import("@/views/module/outsourcingApproval"),
        meta: { title: "外包单位引入" },
      },
      {
        path: "oInformation",
        component: () => import("@/views/module/oInformation"),
        meta: { title: "外包人员单价" },
      },
      {
        path: "leave",
        component: () => import("@/views/module/leave"),
        meta: { title: "请假" },
      },
      {
        path: "overtime",
        component: () => import("@/views/module/overtime"),
        meta: { title: "加班" },
      },
      {
        path: "vacation",
        component: () => import("@/views/module/vacation"),
        meta: { title: "休假" },
      },
      {
        path: "cost1",
        component: () => import("@/views/module/cost1"),
        meta: { title: "沙化费用" },
      },
      {
        path: "cost2",
        component: () => import("@/views/module/cost2"),
        meta: { title: "沙特籍培训费用" },
      },
      {
        path: "cost3",
        component: () => import("@/views/module/cost3"),
        meta: { title: "HRDF返还费用" },
      },
      {
        path: "costChilden1",
        component: () => import("@/views/module/costChilden1"),
        meta: { title: "费用处理" },
      },
      {
        path: "costChilden2",
        component: () => import("@/views/module/costChilden2"),
        meta: { title: "沙特籍培训费用" },
      },
      {
        path: "costChilden3",
        component: () => import("@/views/module/costChilden3"),
        meta: { title: "HRDF返还费用" },
      },
      {
        path: "journal",
        component: () => import("@/views/module/journal"),
        meta: { title: "系统日志" },
      },
      {
        path: "mail",
        component: () => import("@/views/module/mail"),
        meta: { title: "站内信" },
      },
      {
        path: "visaApproval",
        component: () => import("@/views/module/visaApproval"),
        meta: { title: "签证审批" },
      },
      {
        path: "statisticalLedger",
        component: () => import("@/views/module/statisticalLedger"),
        meta: { title: "签证审批统计台账" },
      },
      {
        path: "visitInvitationLetter",
        component: () => import("@/views/module/visitInvitationLetter"),
        meta: { title: "邀请函签证" },
      },
      {
        path: "planeTicket",
        component: () => import("@/views/module/planeTicket"),
        meta: { title: "机票业务" },
      },
      {
        path: "government",
        component: () => import("@/views/module/government"),
        meta: { title: "政府授权" },
      },
      {
        path: "institutionalQualifications",
        component: () => import("@/views/module/institutionalQualifications"),
        meta: { title: "机构资质" },
      },
      {
        path: "invite",
        component: () => import("@/views/module/invite"),
        meta: { title: "邀请函" },
      },
      {
        path: "news",
        component: () => import("@/views/module/news"),
        meta: { title: "消息管理2" },
      },
      {
        path: "changeApplication",
        component: () => import("@/views/module/changeApplication"),
        meta: { title: "员工信息" },
      },
      {
        path: "outsourcingPo",
        component: () => import("@/views/module/outsourcingPo"),
        meta: { title: "服务外包PO签发" },
      },
      {
        path: "ticketPurchase",
        component: () => import("@/views/module/ticketPurchase"),
        meta: { title: "机票请购审批" },
      },
      {
        path: "ticketChange",
        component: () => import("@/views/module/ticketChange"),
        meta: { title: "机票变更申请" },
      },
      {
        path: "transferStation",
        component: () => import("@/views/module/transferStation"),
        meta: { title: "接送站信息" },
      },
      {
        path: "transferStation",
        component: () => import("@/views/module/transferStation"),
        meta: { title: "接送站信息" },
      },
      {
        path: "splitAirfareCosts",
        component: () => import("@/views/module/splitAirfareCosts"),
        meta: { title: "机票费用分割" },
      },
      {
        path: "blacklist",
        component: () => import("@/views/module/blacklist"),
        meta: { title: "不良人员" },
      },
      {
        path: "ticketBooking",
        component: () => import("@/views/module/ticketBooking"),
        meta: { title: "机票订购" },
      },
      {
        path: "SHICost",
        component: () => import("@/views/module/SHICost"),
        meta: { title: "沙特医保费用分割" },
      },
      {
        path: "GOSICost",
        component: () => import("@/views/module/GOSICost"),
        meta: { title: "GOSI费用分割" },
      },
      {
        path: "EOSBCost",
        component: () => import("@/views/module/EOSBCost"),
        meta: { title: "EOSB费用计提" },
      },
      {
        path: "oufm",
        component: () => import("@/views/module/oufm"),
        meta: { title: "外包档案管理" },
      },
      {
        path: "oufmTable",
        component: () => import("@/views/module/oufmTable"),
        meta: { title: "外包档案管理表单页" },
      },
      {
        path: "oufmkh",
        component: () => import("@/views/module/oufmkh"),
        meta: { title: "外包考核管理" },
      },
      {
        path: "oufmjc",
        component: () => import("@/views/module/oufmjc"),
        meta: { title: "外包奖惩管理" },
      },
      {
        path: "ljsoo",
        component: () => import("@/views/module/ljsoo"),
        meta: { title: "人员单价信息签发" },
      },
      {
        path: "ljsoosjk",
        component: () => import("@/views/module/ljsoosjk"),
        meta: { title: "订单数据库" },
      },
      {
        path: "jobCategory",
        component: () => import("@/views/module/jobCategory"),
        meta: { title: "岗位类别" },
      },
      {
        path: "ticketAirfare",
        component: () => import("@/views/module/ticketAirfare"),
        meta: { title: "机票费用" },
      },
      {
        path: "monthlyCredit",
        component: () => import("@/views/module/monthlyCredit"),
        meta: { title: "当地岗位服务费月度挂账" },
      },
      {
        path: "monthlyCreditManagement",
        component: () => import("@/views/module/monthlyCreditManagement"),
        meta: { title: "月度挂账管理" },
      },
      {
        path: "outsourcingFee",
        component: () => import("@/views/module/outsourcingFee"),
        meta: { title: "当地岗位服务外包费明细" },
      },
      {
        path: "flightPath",
        component: () => import("@/views/module/flightPath"),
        meta: { title: "飞行路线" },
      },
      {
        path: "NARManagement",
        component: () => import("@/views/module/NARManagement"),
        meta: { title: "国家地区管理" },
      },
      {
        path: "urbanManagement",
        component: () => import("@/views/module/urbanManagement"),
        meta: { title: "城市管理" },
      },
      {
        path: "internalBorrowingAgreement",
        component: () => import("@/views/module/internalBorrowingAgreement"),
        meta: { title: "内部借工协议" },
      },
      {
        path: "overseasMission",
        component: () => import("@/views/module/overseasMission"),
        meta: { title: "出国(境)任务" },
      },
      {
        path: "overseasPublicSafetyTraining",
        component: () => import("@/views/module/overseasPublicSafetyTraining"),
        meta: { title: "境外公共安全培训" },
      },
      {
        path: "internalBorrowingAgreementCost",
        component: () =>
          import("@/views/module/internalBorrowingAgreementCost"),
        meta: { title: "内部借工协议费用分割" },
      },
      {
        path: "monthlyCreditDetails",
        component: () => import("@/views/module/monthlyCreditDetails"),
        meta: { title: "当地岗位服务费月度挂账明细" },
      },
      {
        path: "sponsorChange",
        component: () => import("@/views/module/sponsorChange"),
        meta: { title: "SPONSOR转入/转出" },
      },
      {
        path: "overseasPublicSafetyTrainingLedger",
        component: () =>
          import("@/views/module/overseasPublicSafetyTrainingLedger"),
        meta: { title: "境外公共安全培训台账" },
      },
      {
        path: "thirdCountryVisaApplication",
        component: () => import("@/views/module/thirdCountryVisaApplication"),
        meta: { title: "第三国签证申请" },
      },
      {
        path: "RecordforVisa",
        component: () => import("@/views/module/RecordforVisa"),
        meta: { title: "签证状态跟踪(赴沙)管理" },
      },
      {
        path: "VisaRecordofBusiness",
        component: () => import("@/views/module/VisaRecordofBusiness"),
        meta: { title: "签证状态跟踪(离沙)管理" },
      },
      {
        path: "visaWarning",
        component: () => import("@/views/module/visaWarning"),
        meta: { title: "签证预警管理" },
      },
      {
        path: "feedbacks",
        component: () => import("@/views/module/feedbacks"),
        meta: { title: "系统优化意见建议" },
      },
      {
        path: "foreignVisitsToChina",
        component: () => import("@/views/module/foreignVisitsToChina"),
        meta: { title: "外国人访华业务审批" },
      },
      {
        path: "employeeMedicalInsuranceClassLevel",
        component: () =>
          import("@/views/module/employeeMedicalInsuranceClassLevel"),
        meta: { title: "员工医保等级管理" },
      },
      {
        path: "employeeMedicalInsuranceRegistration",
        component: () =>
          import("@/views/module/employeeMedicalInsuranceRegistration"),
        meta: { title: "员工医疗保险注册" },
      },
      {
        path: "employeeMedicalInsuranceAdjustmentmentApplication",
        component: () =>
          import(
            "@/views/module/employeeMedicalInsuranceAdjustmentmentApplication"
          ),
        meta: { title: "员工医保变更" },
      },
      {
        path: "employeeMedicalInsuranceRecords",
        component: () =>
          import("@/views/module/employeeMedicalInsuranceRecords"),
        meta: { title: "医保购买或台账" },
      },
      {
        path: "overseasWorksApplicationDetail",
        component: () =>
          import("@/views/module/overseasWorksApplicationDetail"),
        meta: { title: "因公临时出国(境)出行情况" },
      },
      {
        path: "workVisaApplication1",
        component: () => import("@/views/module/workVisaApplication1"),
        meta: { title: "签证指标申购" },
      },
      {
        path: "workVisaApplication2",
        component: () => import("@/views/module/workVisaApplication2"),
        meta: { title: "签证指标授权申请" },
      },
      {
        path: "workVisaApplication3",
        component: () => import("@/views/module/workVisaApplication3"),
        meta: { title: "签证指标取消申请" },
      },
      {
        path: "positionAdjustmentApplicantion",
        component: () =>
          import("@/views/module/positionAdjustmentApplicantion"),
        meta: { title: "员工岗位变动" },
      },
      {
        path: "positionAdjustmentApplicantionOffline",
        component: () =>
          import("@/views/module/positionAdjustmentApplicantionOffline"),
        meta: { title: "员工岗位变动批量调整管理" },
      },
    ],
  },
  {
    path: "/ordinary",
    name: "ordinaryfa",
    component: Layout2,
    redirect: "/ordinary/home",
    children: [
      {
        path: "home",
        name: "ordinary",
        component: () => import("@/views/ordinary/home"),
        meta: { title: "工作平台" },
      },
      {
        path: "leave",
        name: "leave",
        component: () => import("@/views/ordinary/leave"),
        meta: { title: "请假" },
      },
      {
        path: "overtime",
        name: "overtime",
        component: () => import("@/views/ordinary/overtime"),
        meta: { title: "加班" },
      },
      {
        path: "examine",
        name: "examine6",
        component: () => import("@/views/ordinary/examine"),
        meta: { title: "我的申请" },
      },
      {
        path: "systemMessage",
        name: "systemMessage",
        component: () => import("@/views/ordinary/systemMessage"),
        meta: { title: "系统通知" },
      },
      {
        path: "permissions",
        name: "permissions",
        component: () => import("@/views/ordinary/permissions"),
        meta: { title: "404" },
      },
      {
        path: "boxOneBasicInfo",
        name: "boxOneBasicInfo",
        component: () => import("@/views/ordinary/boxOneBasicInfo"),
        meta: { title: "基本信息" },
      },
      {
        path: "boxOneLabor",
        name: "boxOneLabor",
        component: () => import("@/views/ordinary/boxOneLabor"),
        meta: { title: "劳动关系" },
      },
      {
        path: "boxOneSeciality",
        name: "boxOneSeciality",
        component: () => import("@/views/ordinary/boxOneSeciality"),
        meta: { title: "专业技术" },
      },
      {
        path: "boxOneBank",
        name: "boxOneBank",
        component: () => import("@/views/ordinary/boxOneBank"),
        meta: { title: "银行账户" },
      },
      {
        path: "boxOneSalary",
        name: "boxOneSalary",
        component: () => import("@/views/ordinary/boxOneSalary"),
        meta: { title: "人员薪资" },
      },
      {
        path: "boxTwoFreign",
        name: "boxTwoFreign",
        component: () => import("@/views/ordinary/boxTwoFreign"),
        meta: { title: "外事信息" },
      },
      {
        path: "evacuate",
        name: "evacuate",
        component: () => import("@/views/ordinary/evacuate"),
        meta: { title: "撤离" },
      },
      {
        path: "ticketRequisition",
        name: "ticketRequisition",
        component: () => import("@/views/ordinary/ticketRequisition"),
        meta: { title: "机票请购" },
      },
      {
        path: "ticketChange",
        name: "ticketChange",
        component: () => import("@/views/ordinary/ticketChange"),
        meta: { title: "机票变更" },
      },
      {
        path: "payslip",
        name: "payslip",
        component: () => import("@/views/ordinary/Payslip"),
        meta: { title: "工资条" },
      },
      {
        path: "taxClearAll",
        name: "taxClearAll",
        component: () => import("@/views/ordinary/taxClearAll"),
        meta: { title: "个税清缴" },
      },
      {
        path: "incomeCertificate",
        name: "incomeCertificate",
        component: () => import("@/views/ordinary/IncomeCertificate"),
        meta: { title: "收入证明" },
      },
      {
        path: "positionAdjustmentApplicantion",
        name: "positionAdjustmentApplicantion",
        component: () =>
          import("@/views/ordinary/positionAdjustmentApplicantion"),
        meta: { title: "员工岗位变动" },
      },
      {
        path: "feedbacks",
        name: "feedbacks",
        component: () => import("@/views/ordinary/feedbacks"),
        meta: { title: "优化意见" },
      },
      {
        path: "proofEmployment",
        name: "proofEmployment",
        component: () => import("@/views/ordinary/proofEmployment"),
        meta: { title: "工作证明" },
      },
      {
        path: "employmentSeparation",
        name: "employmentSeparation",
        component: () => import("@/views/ordinary/employmentSeparation"),
        meta: { title: "离职证明" },
      },
    ],
  },
  {
    path: "/print",
    name: "print",
    component: () => import("@/views/print.vue"),
    meta: { title: "打印" },
  },

  {
    path: "/signin",
    name: "signin",
    component: () => import("@/views/signin.vue"),
    meta: { title: "登录" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

export default router;
