import router from "./router";

import store from "./store";

//当路由开始跳转时
router.beforeEach((to, from, next) => {
  if (!store.state.userPower) {
    store.commit(
      "changeUserPower",
      JSON.parse(localStorage.getItem("userPower"))
    );
  }
  let index = -1;
  if (store.state.userPower) {
    index = store.state.userPower.findIndex((item) => {
      if (item.Name === "站内信") {
        console.log("11111111111111111", item.Name, to.meta.title);
      }
      return item.Name === to.meta.title;
    });
  }
  document.title = "沙特综合管理系统 | " + to.meta.title;
  console.log("111", from);

  if (index !== -1) {
    next();
  } else {
    switch (to.name) {
      case "signin":
      case "print":
      case "viewPDF":
      case "leave":
      case "overtime":
      case "ordinary":
      case "examine6":
      case "systemMessage":
      case "permissions":
      case "boxOneBasicInfo":
      case "boxOneLabor":
      case "boxOneSeciality":
      case "boxOneBank":
      case "boxOneSalary":
      case "boxTwoFreign":
      case "evacuate":
      case "ticketRequisition":
      case "ticketChange":
      case "payslip":
      case "taxClearAll":
      case "incomeCertificate":
      case "positionAdjustmentApplicantion":
      case "feedbacks":
      case "proofEmployment":
      case "employmentSeparation":
        next();
        break;
      case "home":
        let userInfo = null;
        if (localStorage.getItem("userInfo")) {
          userInfo = JSON.parse(localStorage.getItem("userInfo"));
        }
        if (userInfo && !userInfo.IsAdmin) {
          next("/ordinary/home");
        } else {
          next();
        }
        break;
      default:
        if (localStorage.getItem("userInfo")) {
          let userInfo = JSON.parse(localStorage.getItem("userInfo"));
          if (!userInfo.IsAdmin) {
            location.href = "https://hrms.baishulai.com/ordinary/permissions";
          } else {
            location.href = "https://hrms.baishulai.com/NoPermissions.aspx";
          }
        } else {
          location.href = "https://hrms.baishulai.com/NoPermissions.aspx";
        }
        // next();
        break;
    }
  }
});
