export const navigation = [
  {
    name: "工作平台",
    icon: "fa fa-dashboard",
    active: false,
    subOption: false,
    path: "/module/home",
    index: "dashboard",
  },
  {
    name: "派遣动迁",
    icon: "ion ion-android-plane",
    active: false,
    subOption: true,
    arrow: false,
    height2: 0,
    height3: 0,
    height4: 0,
    index: "2",
    children: [
      {
        name: "人员审批",
        active: false,
        subOption: false,
        index: "rysp",
        href: "/pages/PQDQ/PersonReview.aspx?StatusID=-1",
      },
      {
        name: "签证办理",
        active: false,
        subOption: false,
        index: "qzbl",
        href: "/pages/PQDQ/Visa.aspx?VisaStatus=-1",
      },
      {
        name: "任务批件",
        active: false,
        subOption: false,
        index: "rwpj",
        href: "/pages/PQDQ/Foreign.aspx?ForeignStatus=-1",
      },
      {
        name: "人员动迁",
        active: false,
        subOption: true,
        arrow: false,
        index: "2-4",
        children: [
          {
            name: "国内动迁",
            active: false,
            subOption: false,
            index: "gndq",
            href: "/pages/PQDQ/HomeRelocation.aspx?StatusID=-1",
          },
          {
            name: "离休动迁",
            active: false,
            subOption: true,
            arrow: false,
            index: "2-4-2",
            children: [
              {
                name: "休假",
                active: false,
                subOption: false,
                index: "xj",
                href: "/pages/PQDQ/VacationRelocation.aspx?StatusID=-1",
              },
              {
                name: "撤离",
                active: false,
                subOption: false,
                index: "cl",
                href: "/pages/PQDQ/EvacuateRelocation.aspx?StatusID=-1",
              },
            ],
          },
          {
            name: "机票改签",
            active: false,
            subOption: false,
            index: "jpgq",
            href: "/pages/PQDQ/TicketChange.aspx?StatusID=-1",
          },
        ],
      },
      {
        name: "机票管理",
        active: false,
        subOption: true,
        arrow: false,
        index: "2-5",
        children: [
          {
            name: "机票购买",
            active: false,
            subOption: false,
            index: "jpgm",
            href: "/pages/PQDQ/PlaneTicket.aspx?IsHidden=2",
          },
          {
            name: "机票组",
            active: false,
            subOption: false,
            index: "jpz",
            href: "/pages/PQDQ/TicketGroupList.aspx",
          },
          {
            name: "国家",
            active: false,
            subOption: false,
            index: "gj",
            href: "/pages/PQDQ/CountryList.aspx",
          },
        ],
      },
    ],
  },
  {
    name: "外事业务",
    icon: "ion ion-android-globe",
    active: false,
    subOption: true,
    arrow: false,
    height2: 0,
    height3: 0,
    height4: 0,
    index: "3",
    children: [
      {
        name: "境外人员",
        active: false,
        subOption: true,
        arrow: false,
        index: "3-1",
        children: [
          {
            name: "参保",
            active: false,
            subOption: false,
            index: "canbao",
            href: "/pages/WSYW/JoinInsurance.aspx",
          },
          {
            name: "退保",
            active: false,
            subOption: false,
            index: "tuibao",
            href: "/pages/WSYW/ExitInsurance.aspx",
          },
          {
            name: "保险明细",
            active: false,
            subOption: false,
            index: "biaoxianmingxi",
            href: "/pages/WSYW/JoinInsuranceHistory.aspx",
          },
          {
            name: "到期预警",
            active: false,
            subOption: false,
            index: "daojiyuqi",
            href: "/pages/WSYW/WarningInsurance.aspx",
          },
          {
            name: "出险记录",
            active: false,
            subOption: false,
            index: "chuxianjilu",
            href: "/pages/WSYW/JoinInsuranceRecords.aspx",
          },
        ],
      },
      {
        name: "医疗工伤",
        active: false,
        subOption: true,
        arrow: false,
        index: "3-2",
        children: [
          {
            name: "沙特医保",
            active: false,
            subOption: false,
            index: "yiteshabo",
            href: "/pages/WSYW/MedicalInsurance.aspx",
          },
          {
            name: "工伤保险(GOSI)",
            active: false,
            subOption: false,
            index: "GOSI",
            href: "/pages/WSYW/IndustrialInsurance.aspx",
          },
          {
            name: "沙特医保预警",
            active: false,
            subOption: false,
            index: "shaotebaoxianyujin",
            href: "/pages/WSYW/STWarningInsurance.aspx",
          },
          {
            name: "GOSI预警",
            active: false,
            subOption: false,
            index: "GOSIyujin",
            href: "/pages/WSYW/WarningGOSI.aspx",
          },
        ],
      },
      {
        name: "安全培训",
        active: false,
        subOption: true,
        arrow: false,
        index: "3-3",
        children: [
          {
            name: "公共安全培训",
            active: false,
            subOption: false,
            index: "gonggonganquan2",
            href: "/pages/WSYW/OutSafeTrain.aspx",
          },
          {
            name: "到期预警",
            active: false,
            subOption: false,
            index: "sjxq33",
            href: "/pages/WSYW/OutSafeTrainWarning.aspx",
          },
        ],
      },
      {
        name: "体检管理",
        active: false,
        subOption: false,
        index: "tijianguanli",
        href: "/pages/WSYW/Physicals.aspx",
      },
      {
        name: "暂住证",
        active: false,
        subOption: true,
        arrow: false,
        index: "3-5",
        children: [
          {
            name: "办理",
            active: false,
            subOption: false,
            index: "bl35",
            href: "/pages/WSYW/ShackLicenceNew.aspx?StatusID=-1",
          },
          {
            name: "延期",
            active: false,
            subOption: false,
            index: "yq35",
            href: "/pages/WSYW/ShackLicenceDelay.aspx?StatusID=-1",
          },
          {
            name: "注销",
            active: false,
            subOption: false,
            index: "zx35",
            href: "/pages/WSYW/ShackLicenceCancel.aspx?StatusID=-1",
          },
          {
            name: "职位更改",
            active: false,
            subOption: false,
            index: "zw35",
            href: "/pages/WSYW/ChangePosition.aspx?StatusID=-1",
          },
          {
            name: "预警",
            active: false,
            subOption: false,
            index: "yj35",
            href: "/pages/WSYW/ShackLicenceWarning.aspx?ValidDays=45",
          },
        ],
      },
      {
        name: "境外签证",
        active: false,
        subOption: true,
        arrow: false,
        index: "3-6",
        children: [
          {
            name: "单次往返",
            active: false,
            subOption: false,
            index: "dcwf36",
            href: "/pages/WSYW/SingleVisa.aspx?StatusID=-1",
          },
          {
            name: "多次往返",
            active: false,
            subOption: false,
            index: "dcwfs36",
            href: "/pages/WSYW/ManyVisa.aspx?StatusID=-1",
          },
          {
            name: "离境签证",
            active: false,
            subOption: false,
            index: "ljqz36",
            href: "/pages/WSYW/ExitVisa.aspx?StatusID=-1",
          },
          {
            name: "签证注销",
            active: false,
            subOption: false,
            index: "qzzx36",
            href: "/pages/WSYW/CancelExitReentryVisa.aspx?StatusID=-1",
          },
          {
            name: "签证延期",
            active: false,
            subOption: false,
            index: "1zyq36",
            href: "/pages/WSYW/RenewExitReentryVisa.aspx?StatusID=-1",
          },
          {
            name: "签证预警",
            active: false,
            subOption: false,
            index: "1zyj36",
            href: "/pages/WSYW/VisaWarning.aspx",
          },
          {
            name: "签证记录",
            active: false,
            subOption: false,
            index: "yzjl36",
            href: "/pages/WSYW/ExitReentryVisaRecord.aspx",
          },
        ],
      },
      {
        name: "访问签证",
        active: false,
        subOption: true,
        arrow: false,
        index: "3-7",
        children: [
          {
            name: "新办",
            active: false,
            subOption: false,
            index: "xb7",
            href: "/pages/WSYW/VisitVisaNew.aspx?StatusID=-1",
          },
          {
            name: "延期",
            active: false,
            subOption: false,
            index: "yq7",
            href: "/pages/WSYW/VisitVisaDelay.aspx?StatusID=-1",
          },
        ],
      },
      {
        name: "沙特驾照",
        active: false,
        subOption: true,
        arrow: false,
        index: "3-8",
        children: [
          {
            name: "新办",
            active: false,
            subOption: false,
            index: "xb38",
            href: "/pages/WSYW/DrivingLicenseNew.aspx?StatusID=-1",
          },
          {
            name: "延期",
            active: false,
            subOption: false,
            index: "yx38",
            href: "/pages/WSYW/DrivingLicenseDelay.aspx?StatusID=-1",
          },
          {
            name: "驾照记录",
            active: false,
            subOption: false,
            index: "zj38",
            href: "/pages/WSYW/DrivingLicenseRecord.aspx?StatusID=-1",
          },
        ],
      },
      {
        name: "护照业务",
        active: false,
        subOption: true,
        arrow: false,
        index: "3-9",
        children: [
          {
            name: "延期",
            active: false,
            subOption: false,
            index: "yq39",
            href: "/pages/WSYW/RenewPassport.aspx?StatusID=-1",
          },
          {
            name: "转信息",
            active: false,
            subOption: false,
            index: "zx39",
            href: "/pages/WSYW/TransferPassport.aspx?StatusID=-1",
          },
          {
            name: "护照预警",
            active: false,
            subOption: false,
            index: "hz39",
            href: "/pages/WSYW/Passport.aspx?ValidDays=180",
          },
        ],
      },
      {
        name: "SPONSOR",
        active: false,
        subOption: false,
        index: "SPONSOR",
        href: "/pages/WSYW/TransferSponsor.aspx?StatusID=-1",
      },
      {
        name: "证件转交",
        active: false,
        subOption: false,
        index: "zjzj",
        href: "/pages/WSYW/DocumentConvert.aspx?StatusID=-1",
      },
      {
        name: "数据统计",
        active: false,
        subOption: true,
        arrow: false,
        index: "3-12",
        children: [
          {
            name: "签证指标",
            active: false,
            subOption: false,
            index: "qz312",
            href: "/pages/WSYW/DataVisaNorm.aspx",
          },
          {
            name: "签证使用量",
            active: false,
            subOption: false,
            index: "qzs312",
            href: "/pages/WSYW/DataVisaAmount.aspx",
          },
          {
            name: "机票统计",
            active: false,
            subOption: false,
            index: "qzj312",
            href: "/pages/WSYW/DataPlaneTicket.aspx",
          },
          {
            name: "外事办理量",
            active: false,
            subOption: false,
            index: "qzw312",
            href: "/pages/WSYW/DataForeignDeal.aspx",
          },
          {
            name: "境外人保日志",
            active: false,
            subOption: false,
            index: "qzjw312",
            href: "/pages/WSYW/JoinInsuranceLog.aspx",
          },
        ],
      },
    ],
  },
  {
    name: "人事管理",
    icon: "ion ion-android-people",
    active: false,
    subOption: true,
    arrow: false,
    height2: 0,
    height3: 0,
    height4: 0,
    index: "4",
    children: [
      {
        name: "人力调配",
        active: false,
        subOption: true,
        arrow: false,
        index: "4-1",
        children: [
          {
            name: "项目调转",
            active: false,
            subOption: false,
            index: "xm41",
            href: "/pages/NZGL/DeployOverseas.aspx?StatusID=-1",
          },
          {
            name: "人力需求计划",
            active: false,
            subOption: false,
            index: "rljhsq",
            path: "/module/manpower",
          },
        ],
      },
      {
        name: "合同管理",
        active: false,
        subOption: false,
        index: "hdgl",
        href: "/pages/NZGL/Contract.aspx",
      },
      {
        name: "休假管理",
        active: false,
        subOption: false,
        index: "xjgl",
        href: "/pages/NZGL/HolidayManagement.aspx",
      },
      {
        name: "工资奖金",
        active: false,
        subOption: true,
        arrow: false,
        index: "4-4",
        children: [
          {
            name: "国内薪资计发",
            active: false,
            subOption: true,
            arrow: false,
            index: "4-4-1",
            children: [
              {
                name: "国内薪资计发",
                active: false,
                subOption: false,
                index: "gnxj441",
                href: "/pages/NZGL/HomeSalaryBase.aspx",
              },
              {
                name: "国内奖金计发",
                active: false,
                subOption: false,
                index: "gnjj441",
                href: "/pages/NZGL/HomeBonusBase.aspx",
              },
            ],
          },
          {
            name: "国外薪资计发",
            active: false,
            subOption: true,
            arrow: false,
            index: "4-4-2",
            children: [
              {
                name: "中国籍薪资计算",
                active: false,
                subOption: true,
                arrow: false,
                index: "4-4-2-1",
                children: [
                  {
                    name: "非直聘",
                    active: false,
                    subOption: false,
                    index: "a4421",
                    href: "/pages/NZGL/ChineseWagesReckon.aspx",
                  },
                  {
                    name: "直聘",
                    active: false,
                    subOption: false,
                    index: "b4421",
                    href: "/pages/NZGL/ChineseWagesReckonZP.aspx",
                  },
                ],
              },
              {
                name: "外籍薪资计算",
                active: false,
                subOption: false,
                index: "b442",
                href: "/pages/NZGL/ForeignerWagesReckon.aspx",
              },
              {
                name: "奖金计算",
                active: false,
                subOption: false,
                index: "c442",
                href: "/pages/NZGL/BonusReckon.aspx",
              },
              {
                name: "个税计算",
                active: false,
                subOption: false,
                index: "d442",
                href: "/pages/NZGL/ChineseIncomeTaxReckon.aspx",
              },
              {
                name: "分包单位薪资计算",
                active: false,
                subOption: true,
                arrow: false,
                index: "4-4-2-5",
                children: [
                  {
                    name: "中国籍",
                    active: false,
                    subOption: false,
                    index: "a4425",
                    href: "/pages/NZGL/SubWagesReckon.aspx",
                  },
                  {
                    name: "外籍",
                    active: false,
                    subOption: false,
                    index: "b4425",
                    href: "/pages/NZGL/ForeignerSubWagesReckon.aspx",
                  },
                  {
                    name: "中国籍New",
                    active: false,
                    subOption: false,
                    index: "c4425",
                    href: "/pages/NZGL/ChineseSalaryTableMain.aspx",
                  },
                ],
              },
              {
                name: "薪资发放",
                active: false,
                subOption: true,
                arrow: false,
                index: "4-4-2-6",
                children: [
                  {
                    name: "现金支付",
                    active: false,
                    subOption: false,
                    index: "a4426",
                    href: "/pages/NZGL/CashPaymentBase.aspx",
                  },
                  {
                    name: "打卡支付",
                    active: false,
                    subOption: false,
                    index: "b4426",
                    href: "/pages/NZGL/CardPayment.aspx",
                  },
                ],
              },
              {
                name: "暂存统计",
                active: false,
                subOption: false,
                index: "zancumtongji",
                href: "/pages/NZGL/TransactionHistoryStatistics.aspx",
              },
              {
                name: "汇率设置",
                active: false,
                subOption: false,
                index: "huilushezhi",
                href: "/pages/NZGL/ExchangeRate.aspx",
              },
            ],
          },
          {
            name: "当地岗位外包费",
            active: false,
            subOption: false,
            index: "c44",
            href: "/pages/NZGL/LocalOutsourceCost.aspx",
          },
          {
            name: "成本与费用分割",
            active: false,
            subOption: false,
            index: "d44",
            href: "/pages/NZGL/CostExpense.aspx",
          },
          {
            name: "收入统计",
            active: false,
            subOption: true,
            arrow: false,
            index: "4-4-5",
            children: [
              {
                name: "中国籍",
                active: false,
                subOption: false,
                index: "b445",
                href: "/pages/NZGL/ChineseIncomeDetails.aspx",
              },
              {
                name: "中国籍New",
                active: false,
                subOption: false,
                index: "c445",
                href: "/pages/NZGL/ChineseIncomeDetailsNew.aspx",
              },
              {
                name: "中国籍（直聘）",
                active: false,
                subOption: false,
                index: "d445",
                href: "/pages/NZGL/ChineseIncomeDetailsZP.aspx",
              },
              {
                name: "外籍",
                active: false,
                subOption: false,
                index: "e445",
                href: "/pages/NZGL/ForeignerIncomeDetails.aspx",
              },
            ],
          },
        ],
      },
      {
        name: "人事报表",
        active: false,
        subOption: true,
        arrow: false,
        index: "4-5",
        children: [
          {
            name: "当地岗位外包/分包人数",
            active: false,
            subOption: false,
            index: "a45",
            href: "/pages/NZGL/LocalPosition.aspx",
          },
          {
            name: "HR周报",
            active: false,
            subOption: false,
            index: "b45",
            href: "/pages/NZGL/HRWeekly.aspx",
          },
          {
            name: "人力负荷统计表",
            active: false,
            subOption: false,
            index: "c45",
            href: "/pages/NZGL/HRReportMain.aspx",
          },
          {
            name: "年度工资表",
            active: false,
            subOption: false,
            index: "d45",
            href: "/pages/NZGL/SalaryReport.aspx",
          },
          {
            name: "暂存日志",
            active: false,
            subOption: false,
            index: "e45",
            href: "/pages/NZGL/TransactionHistoryLog.aspx",
          },
          {
            name: "境外机构人员统计表",
            active: false,
            subOption: false,
            index: "f45",
            href: "/pages/NZGL/HumanStatistics.aspx",
          },
          {
            name: "人员调转明细",
            active: false,
            subOption: false,
            index: "g45",
            href: "/pages/NZGL/StaffTransferLog.aspx",
          },
          {
            name: "人工成本稽核表",
            active: false,
            subOption: false,
            index: "h45",
            href: "/pages/NZGL/CostCheck.aspx",
          },
        ],
      },
      {
        name: "证件管理",
        active: false,
        subOption: true,
        arrow: false,
        index: "4-6",
        children: [
          {
            name: "护照",
            active: false,
            subOption: false,
            index: "a46",
            href: "/pages/NZGL/CertificatePassportList.aspx",
          },
          {
            name: "暂住证",
            active: false,
            subOption: false,
            index: "b46",
            href: "/pages/NZGL/CertificateIQAMAList.aspx",
          },
        ],
      },
      {
        name: "人事仪表盘",
        active: false,
        subOption: false,
        index: "arsybp",
        href: "/pages/NZGL/GlobalReport.aspx",
      },
      {
        name: "薪资设置",
        active: false,
        subOption: true,
        arrow: false,
        index: "4-8",
        children: [
          {
            name: "人员薪资设置",
            active: false,
            subOption: true,
            arrow: false,
            index: "4-8-1",
            children: [
              {
                name: "薪资标准项",
                active: false,
                subOption: false,
                index: "a481",
                href: "/pages/NZGL/SalaryUserItems.aspx",
              },
              {
                name: "人员薪资标准",
                active: false,
                subOption: false,
                index: "b481",
                href: "/pages/NZGL/SalaryUserStandard.aspx",
              },
            ],
          },
          {
            name: "自定义薪资表项",
            active: false,
            subOption: false,
            index: "zdyxjbx",
            href: "/pages/NZGL/SalaryItems.aspx",
          },
          {
            name: "自定义薪资表",
            active: false,
            subOption: false,
            index: "zdyxjb",
            href: "/pages/NZGL/SalaryItemTable.aspx",
          },
          {
            name: "关联薪资表",
            active: false,
            subOption: true,
            arrow: false,
            index: "4-8-4",
            children: [
              {
                name: "中文薪资表",
                active: false,
                subOption: false,
                index: "a484",
                href: "/pages/NZGL/SalaryItemTableRelations.aspx",
              },
              {
                name: "英文薪资表",
                active: false,
                subOption: false,
                index: "b484",
                href: "/pages/NZGL/SalaryItemTableRelations2.aspx",
              },
            ],
          },
        ],
      },
      {
        name: "外包管理",
        active: false,
        subOption: true,
        arrow: false,
        index: "waibaoguanli",
        children: [
          {
            name: "外包单位引入",
            active: false,
            subOption: false,
            index: "gwwbrydwyrsp",
            path: "/module/outsourcingApproval",
          },
          {
            name: "外包档案管理",
            active: false,
            subOption: false,
            index: "wabaodanganguanli",
            path: "/module/oufm",
          },
          // {
          //   name: "外包考核管理",
          //   active: false,
          //   subOption: false,
          //   index: "waibaokaoheguanli",
          //   path: "/module/oufmkh",
          // },
          // {
          //   name: "外包奖惩管理",
          //   active: false,
          //   subOption: false,
          //   index: "waibaojiangchengguanli",
          //   path: "/module/oufmjc",
          // },
        ],
      },
      {
        name: "订单管理",
        active: false,
        subOption: true,
        arrow: false,
        index: "dingdanguanli2",
        children: [
          {
            name: "外包人员单价",
            active: false,
            subOption: false,
            index: "gwwbrydwydpfb",
            path: "/module/oInformation",
          },
          {
            name: "服务外包PO签发",
            active: false,
            subOption: false,
            index: "renyuanxinxidanjiaqianfa",
            path: "/module/ljsoo",
          },
          {
            name: "订单数据库",
            active: false,
            subOption: false,
            index: "dingdanshujuku",
            path: "/module/ljsoosjk",
          },
        ],
      },
      {
        name: "结算管理",
        active: false,
        subOption: true,
        arrow: false,
        index: "dingdanguanli2",
        children: [
          {
            name: "当地岗位服务费月度挂账",
            active: false,
            subOption: false,
            index: "gwwmonthlyCredit",
            path: "/module/monthlyCredit",
          },
          {
            name: "月度挂账管理",
            active: false,
            subOption: false,
            index: "gwwmonthlyCreditManagement",
            path: "/module/monthlyCreditManagement",
          },

          {
            name: "当地岗位服务外包费明细",
            active: false,
            subOption: false,
            index: "gwwoutOutsourcingFee",
            path: "/module/outsourcingFee",
          },
        ],
      },
      {
        name: "请假",
        active: false,
        subOption: false,
        index: "qiangjia123",
        path: "/module/leave",
      },
      {
        name: "加班",
        active: false,
        subOption: false,
        index: "jiaban123",
        path: "/module/overtime",
      },
      // {
      //   name: "休假",
      //   active: false,
      //   subOption: false,
      //   index: "renshixiujia",
      //   path: "/module/vacation",
      // },
    ],
  },
  {
    name: "档案管理",
    icon: "ion ion-social-rss",
    active: false,
    subOption: true,
    arrow: false,
    height2: 0,
    height3: 0,
    height4: 0,
    index: "5",
    children: [
      {
        name: "人员档案",
        active: false,
        subOption: false,
        index: "a5",
        href: "/pages/User/UserList.aspx?ForeignRelationStatusID=1",
      },
      {
        name: "劳动关系",
        active: false,
        subOption: false,
        index: "b5",
        href: "/pages/User/UserLaborRelations.aspx",
      },
      {
        name: "专业技术",
        active: false,
        subOption: false,
        index: "c5",
        href: "/pages/User/UserTechnology.aspx",
      },
      {
        name: "银行账户",
        active: false,
        subOption: false,
        index: "d5",
        href: "/pages/User/UserBank.aspx",
      },
      {
        name: "外事业务",
        active: false,
        subOption: false,
        index: "e5",
        href: "/pages/User/UserForeignBusiness.aspx",
      },
      {
        name: "人员薪资",
        active: false,
        subOption: false,
        index: "f5",
        href: "/pages/User/UserPay.aspx",
      },
      {
        name: "组织机构",
        active: false,
        subOption: true,
        arrow: false,
        index: "g5",
        children: [
          {
            name: "组织关系",
            active: false,
            subOption: false,
            index: "g51",
            href: "/pages/User/ProjectCompanyRelation.aspx",
          },
        ],
      },
      {
        name: "授权码表",
        active: false,
        subOption: false,
        index: "h5",
        href: "/pages/User/AuthorizationCode.aspx",
      },
      {
        name: "组合查询",
        active: false,
        subOption: false,
        index: "i5",
        href: "/pages/User/UserCombineQuery.aspx",
      },
      {
        name: "历史信息",
        active: false,
        subOption: true,
        index: "j5",
        arrow: false,
        children: [
          {
            name: "历史信息",
            active: false,
            subOption: false,
            index: "j51",
            href: "/pages/User/HistoryLog.aspx",
          },
          {
            name: "人员备份",
            active: false,
            subOption: false,
            index: "j52",
            href: "/pages/User/MonthlyUserInfo.aspx",
          },
        ],
      },
      {
        name: "基础数据",
        active: false,
        subOption: true,
        arrow: false,
        index: "5-11",
        children: [
          {
            name: "岗位类别",
            active: false,
            subOption: false,
            index: "a511",
            href: "/pages/User/PostCategoryList.aspx",
          },
        ],
      },
    ],
  },
  {
    name: "审批管理",
    icon: "ion ion-ios-paper",
    active: false,
    subOption: true,
    arrow: false,
    height2: 0,
    height3: 0,
    height4: 0,
    index: "10",
    children: [
      {
        name: "流程管理",
        active: false,
        subOption: false,
        path: "/module/procedure",
        index: "a10",
      },
      {
        name: "我的审批",
        active: false,
        subOption: true,
        arrow: false,
        index: "b10",
        children: [
          {
            name: "待办事项",
            active: false,
            subOption: false,
            index: "b10-1",
            path: "/module/examine1",
          },
          {
            name: "已办事项",
            active: false,
            subOption: false,
            index: "b10-2",
            path: "/module/examine2",
          },
          {
            name: "跟踪事项",
            active: false,
            subOption: false,
            index: "b10-3",
            path: "/module/examine3",
          },
          {
            name: "已结束",
            active: false,
            subOption: false,
            index: "b10-4",
            path: "/module/examine4",
          },
        ],
      },
      {
        name: "待我查阅",
        path: "/module/consult",
        active: false,
        subOption: false,
        index: "c10",
      },
    ],
  },
  {
    name: "组织机构",
    icon: "ion ion-android-funnel",
    active: false,
    subOption: true,
    arrow: false,
    height2: 0,
    height3: 0,
    height4: 0,
    index: "11",
    children: [
      {
        name: "组织机构",
        active: false,
        subOption: true,
        arrow: false,
        index: "11-1",
        children: [
          {
            name: "项目部",
            active: false,
            subOption: false,
            index: "a111",
            path: "/module/project",
          },
          {
            name: "单位",
            active: false,
            subOption: false,
            index: "b111",
            path: "/module/company",
          },
          {
            name: "部门",
            active: false,
            subOption: false,
            index: "c111",
            path: "/module/department",
          },
          {
            name: "组织关系",
            active: false,
            subOption: false,
            index: "d111",
            path: "/module/organization",
          },
        ],
      },
      {
        name: "成本中心",
        active: false,
        subOption: false,
        index: "b11",
        path: "/module/costCenter",
      },
      {
        name: "岗位职务",
        active: false,
        subOption: false,
        index: "c11",
        path: "/module/position",
      },
      {
        name: "权限配置",
        active: false,
        subOption: false,
        path: "/module/rights",
        index: "d11",
      },
      {
        name: "角色管理",
        active: false,
        subOption: false,
        path: "/module/roles",
        index: "e11",
      },
      {
        name: "系统模块",
        active: false,
        subOption: false,
        path: "/module/system",
        index: "f11",
      },
    ],
  },
  {
    name: "费用管理",
    icon: "ion ion-social-yen",
    active: false,
    subOption: true,
    arrow: false,
    height2: 0,
    height3: 0,
    height4: 0,
    index: "12",
    children: [
      {
        name: "费用管理",
        active: false,
        subOption: true,
        arrow: false,
        index: "d12",
        children: [
          {
            name: "费用处理",
            active: false,
            subOption: false,
            index: "ad12",
            path: "/module/costChilden1",
          },
        ],
      },
      {
        name: "沙化费用",
        active: false,
        subOption: false,
        index: "a12",
        path: "/module/cost1",
      },
      {
        name: "沙特籍培训费用",
        active: false,
        subOption: false,
        index: "b12",
        path: "/module/cost2",
      },
      {
        name: "HRDF返还费用",
        active: false,
        subOption: false,
        index: "c12",
        path: "/module/cost3",
      },
    ],
  },
  {
    name: "消息管理",
    icon: "ion ion-chatbox-working",
    active: false,
    subOption: false,
    href: "/pages/NEWS/News.aspx",
    index: "xxtx",
  },
  {
    name: "帮助中心",
    icon: "ion ion-help-circled",
    active: false,
    subOption: true,
    arrow: false,
    height2: 0,
    height3: 0,
    height4: 0,
    index: "7",
    children: [
      {
        name: "操作帮助",
        active: false,
        subOption: false,
        index: "a7",
        href: "/pages/HELP/Help.aspx",
      },
      {
        name: "文件下载",
        active: false,
        subOption: false,
        index: "b7",
        href: "/pages/HELP/FileDownload.aspx",
      },
    ],
  },
  {
    name: "系统设置",
    icon: "ion ion-gear-b",
    active: false,
    subOption: true,
    arrow: false,
    height2: 0,
    height3: 0,
    height4: 0,
    index: "8",
    children: [
      {
        name: "系统日志",
        active: false,
        subOption: false,
        index: "d8",
        path: "/module/journal",
      },
      {
        name: "账号管理",
        active: false,
        subOption: false,
        index: "a8",
        href: "/pages/SYSTEM/AdminList.aspx",
      },
      {
        name: "修改密码",
        active: false,
        subOption: false,
        index: "b8",
        href: "/pages/SYSTEM/PassWord.aspx",
      },
      {
        name: "退出系统",
        active: false,
        subOption: false,
        index: "c8",
        path: "/signin",
      },
    ],
  },
  {
    name: "问题反馈",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "wtfk",
    href: "/pages/Feedback/Feedback.aspx",
  },
  {
    name: "站内信",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyznx",
    path: "/module/mail",
  },
  {
    name: "签证审批",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyqzsp",
    path: "/module/visaApproval",
  },
  {
    name: "签证审批统计台账",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glystatisticalLedger",
    path: "/module/statisticalLedger",
  },
  {
    name: "不良人员",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glysblacklist",
    path: "/module/blacklist",
  },
  {
    name: "员工访沙邀请函",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyvisitInvitationLetter",
    path: "/module/invite",
  },
  {
    name: "携家属访沙邀请函",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyvisitInvitationLetterqz",
    path: "/module/visitInvitationLetter",
  },
  {
    name: "外国人访华业务审批",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyforeignVisitsToChina",
    path: "/module/foreignVisitsToChina",
  },

  {
    name: "撤离动迁",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyplaneTicket",
    path: "/module/planeTicket",
  },
  {
    name: "公司及政府系统",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glygovernment",
    path: "/module/government",
  },
  {
    name: "机构资质",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyinstitutionalQualifications",
    path: "/module/institutionalQualifications",
  },
  {
    name: "消息管理2",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyXxgl2",
    path: "/module/news",
  },
  {
    name: "人员信息",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyRyxx",
    path: "/module/changeApplication",
  },
  // {
  //   name: "服务外包PO签发",
  //   icon: "ion ion-compose",
  //   active: false,
  //   subOption: false,
  //   index: "glyoutsourcingJob",
  //   path: "/module/outsourcingPo",
  // },
  {
    name: "机票请购审批",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyticketPurchase",
    path: "/module/ticketPurchase",
  },
  {
    name: "机票变更申请",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyoutsourcingJob",
    path: "/module/ticketChange",
  },
  {
    name: "机票订购",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyticketBooking",
    path: "/module/ticketBooking",
  },
  {
    name: "机票费用",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyticketAirfare",
    path: "/module/ticketAirfare",
  },

  {
    name: "接送站信息",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glytransferStation",
    path: "/module/transferStation",
  },
  {
    name: "机票费用分割",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glysplitAirfareCosts",
    path: "/module/splitAirfareCosts",
  },
  {
    name: "沙特医保费用分割",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glySHICost",
    path: "/module/SHICost",
  },
  {
    name: "GOSI费用分割",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyGOSICost",
    path: "/module/GOSICost",
  },
  {
    name: "EOSB费用计提",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyEOSBCost",
    path: "/module/EOSBCost",
  },
  {
    name: "岗位类别",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyjobCategory",
    path: "/module/jobCategory",
  },
  {
    name: "飞行路线",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyflightPath",
    path: "/module/flightPath",
  },
  {
    name: "国家地区管理",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyflightPath",
    path: "/module/NARManagement",
  },
  {
    name: "城市管理",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyflightPath",
    path: "/module/urbanManagement",
  },
  {
    name: "内部借工协议",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyInternalBorrowingAgreement",
    path: "/module/internalBorrowingAgreement",
  },
  {
    name: "出国(境)任务",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyoverseasMission",
    path: "/module/overseasMission",
  },
  {
    name: "因公临时出国(境)出行情况",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyoverseasWorksApplicationDetail",
    path: "/module/overseasWorksApplicationDetail",
  },
  {
    name: "境外公共安全培训",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyoverseasPublicSafetyTraining",
    path: "/module/overseasPublicSafetyTraining",
  },
  {
    name: "境外公共安全培训台账",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyoverseasPublicSafetyTraining",
    path: "/module/overseasPublicSafetyTrainingLedger",
  },
  {
    name: "内部借工协议费用分割",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyinternalBorrowingAgreementCost",
    path: "/module/internalBorrowingAgreementCost",
  },
  {
    name: "SPONSOR转入/转出",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyForeignAffairsBusiness",
    path: "/module/sponsorChange",
  },
  {
    name: "第三国签证申请",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glythirdCountryVisaApplication",
    path: "/module/thirdCountryVisaApplication",
  },
  {
    name: "签证状态跟踪(赴沙)管理",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyRecordforVisa",
    path: "/module/RecordforVisa",
  },
  {
    name: "签证状态跟踪(离沙)管理",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyVisaRecordofBusiness",
    path: "/module/VisaRecordofBusiness",
  },
  {
    name: "系统优化意见建议",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyFeedbacks",
    path: "/module/feedbacks",
  },
  {
    name: "员工医保等级管理",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyemployeeMedicalInsuranceClassLevel",
    path: "/module/employeeMedicalInsuranceClassLevel",
  },
  {
    name: "员工医疗保险注册",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyemployeeMedicalInsuranceRegistration",
    path: "/module/employeeMedicalInsuranceRegistration",
  },
  {
    name: "员工医保变更",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyemployeeMedicalInsuranceAdjustmentmentApplication",
    path: "/module/employeeMedicalInsuranceAdjustmentmentApplication",
  },
  {
    name: "医保购买或台账",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyemployeeMedicalInsuranceRecords",
    path: "/module/employeeMedicalInsuranceRecords",
  },
  {
    name: "签证预警管理",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyVisaWarning",
    path: "/module/visaWarning",
  },
  {
    name: "签证指标申购",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyworkVisaApplication1",
    path: "/module/workVisaApplication1",
  },
  {
    name: "签证指标授权申请",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyworkVisaApplication2",
    path: "/module/workVisaApplication2",
  },
  {
    name: "签证指标取消申请",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyworkVisaApplication3",
    path: "/module/workVisaApplication3",
  },
  {
    name: "员工岗位变动",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyworkpositionAdjustmentApplicantion",
    path: "/module/positionAdjustmentApplicantion",
  },
  {
    name: "员工岗位变动批量调整管理",
    icon: "ion ion-compose",
    active: false,
    subOption: false,
    index: "glyworkpositionAdjustmentApplicantionOffline",
    path: "/module/positionAdjustmentApplicantionOffline",
  },
];
