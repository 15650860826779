<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {};
  },
};
</script>

<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
#app {
  font-size: 16px;
  color: #333333;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.el-cascader-menu{
    height: 200px;
}
</style>
