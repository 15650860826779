<template>
  <div class="main" v-if="$store.state.userInfo">
    <!-- 顶部一栏 -->
    <div class="main-top">
      <div :class="['left', { 'left-close': !openOrGate }]">
        <b v-if="openOrGate || (smallScreen && !openOrGate)">HRMS/SPEC/</b>
        <span v-if="openOrGate || (smallScreen && !openOrGate)">SNEI</span>
        <div class="sne" v-if="!openOrGate && !smallScreen">SNE</div>
      </div>
      <div class="right">
        <div class="icon">
          <i class="fa fa-bars" aria-hidden="true"></i>
        </div>
        <div class="right-box">
          <div class="button-fa" @click="goModule" v-if="$store.state.userInfo.IsAdmin">
            <button>管理中心</button>
          </div>
          <div
            :class="['user-box', { 'user-active': isUserIfo }]"
            @click="isUserIfo = true"
            v-click-outside="handleBlur"
          >
            <img
              class="user-img"
              v-if="$store.state.userInfo && $store.state.userInfo.UserPic"
              :src="$store.state.userInfo.UserPic"
            />
            <img v-else class="user-img" src="@/assets/image/1.jpg" />
            <span class="user-name" v-if="$store.state.userInfo">{{
              $store.state.userInfo.RealName
                ? $store.state.userInfo.RealName
                : $store.state.userInfo.UserName
            }}</span>
          </div>
        </div>
        <!-- 弹框 -->
        <div v-if="isUserIfo" class="pop-frame">
          <div class="user-header">
            <div class="circle-box">
              <img
                class="img-circle"
                v-if="$store.state.userInfo && $store.state.userInfo.UserPic"
                :src="$store.state.userInfo.UserPic"
              />
              <img v-else class="img-circle" src="@/assets/image/1.jpg" />
            </div>
            <p v-if="$store.state.userInfo">
              您好，{{
                $store.state.userInfo.RealName
                  ? $store.state.userInfo.RealName
                  : $store.state.userInfo.UserName
              }}！
              <small
                >上次登录时间：{{ $store.state.userInfo.LastLoginDate }}</small
              >
            </p>
          </div>
          <div class="user-footer">
            <div class="pull-left" @click="ziliao">资料</div>
            <div class="pull-right" @click="setExit">注销</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 主体内容区域 -->
    <div class="main-content">
      <!-- 导航 -->
      <div class="right">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import { setExit } from "@/api/user";
const clickOutside = {
  bind(el, binding) {
    // 在元素上绑定一个点击事件监听器
    el.clickOutsideEvent = function (event) {
      // 检查点击事件是否发生在元素的内部
      if (!(el === event.target || el.contains(event.target))) {
        // 如果点击事件发生在元素的外部，则触发指令绑定的方法
        binding.value(event);
      }
    };
    // 在文档上添加点击事件监听器
    document.addEventListener("click", el.clickOutsideEvent);
  },
  unbind(el) {
    // 在元素上解除点击事件监听器
    document.removeEventListener("click", el.clickOutsideEvent);
  },
};
export default {
  data() {
    return {
      openOrGate: true, //导航开关
      smallScreen: false, //是否小屏
      isUserIfo: false,
      timer: null,
      url: "https://hrms.baishulai.com",
    };
  },
  directives: {
    "click-outside": clickOutside, // 注册自定义指令
  },
  methods: {
    // 导航栏开关
    changeSwitch() {
      this.openOrGate = !this.openOrGate;
    },
    // 监听屏幕宽度变化
    scrollToTop() {
      const width = document.body.clientWidth;
      if (width > 766 && !this.openOrGate && !this.smallScreen) {
        return;
      }

      if (width <= 766) {
        this.smallScreen = true;
      } else {
        this.smallScreen = false;
        this.openOrGate = true;
      }
    },
    //点击其它区域
    handleBlur(event) {
      this.isUserIfo = false;
    },

    ziliao() {
      location.href =
        this.url + "/pages/SYSTEM/AdminEdit.aspx?type=edit&userid=1";
    },
    setExit() {
      setExit().then(() => {
        this.$router.push("/signin");
      });
    },
    goModule(){
      this.$router.push("/module");
    }
  },
  mounted() {
    if (!this.$store.state.userInfo) {
      this.$store.commit(
        "changeUserInfo",
        JSON.parse(localStorage.getItem("userInfo"))
      );
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.scrollToTop, true);
  },
};
</script>

<style lang="scss" scoped>
.main {
  min-width: 100%;
  //   顶部一栏
  .main-top {
    min-height: 50px;
    background-color: #3c8dbc;
    display: flex;
    .left {
      flex-shrink: 0;
      width: 230px;
      height: 50px;
      background-color: #367fa9;
      cursor: pointer;
      color: #fff;
      line-height: 50px;
      text-align: center;
      font-size: 20px;
      transition: width 0.3s ease-in-out;
      overflow: hidden;
      b {
        font-weight: 700;
      }
    }
    .left:hover {
      background-color: #357ca5;
    }
    .left-close {
      width: 50px;
      .sne {
        font-size: 18px;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        // margin-bottom: 5px;
        font-weight: 700;
      }
    }
    .right {
      display: flex;
      justify-content: space-between;
      width: 100%;
      transition: width 0.3s ease-in-out;
      position: relative;
      .icon {
        flex-shrink: 0;
        width: 12px;
        display: flex;
        padding: 15px 15px;
        color: #fff;
        cursor: pointer;
        .fa {
          font-size: 14px;
          line-height: 1.42857143;
        }
      }
      .icon:hover {
        background-color: #367fa9;
      }
      .right-box {
        display: flex;
        height: 100%;
        .button-fa {
          display: flex;
          align-items: center;
          height: 100%;
          button {
            height: 100%;
            line-height: 199%;
            padding: 0;
            border: none;
            align-items: center;
            background-color: #3c8dbc;
            color: #fff;
            cursor: pointer;
            padding: 0 12px;
          }
        }
        .button-fa:hover {
          button {
            background-color: #367fa9;
          }
        }
      }
      .user-box {
        padding: 0 15px;
        display: flex;
        align-items: center;
        .user-img {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          margin-right: 10px;
          margin-top: 1px;
        }
        .user-name {
          font-size: 14px;
          color: #fff;
          font-family: "Microsoft YaHei";
          margin-bottom: 0.5px;
        }
      }
      .user-box:hover {
        background-color: #367fa9;
      }
      .user-active {
        background-color: #367fa9;
      }
      .pop-frame {
        position: absolute;
        right: 0;
        width: 278.4px;
        top: 50px;
        padding: 1px;
        background-color: #fff;
        z-index: 100;
        .user-header {
          height: 155px;
          padding: 10px;
          text-align: center;
          background-color: #3c8dbc;
          overflow: hidden;
          .circle-box {
            width: 85px;
            height: 85px;
            border-radius: 44px;
            border: 3px solid rgba(255, 255, 255, 0.2);
            overflow: hidden;
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            .img-circle {
              width: 100%;
              height: 100%;
            }
          }
          p {
            color: rgba(255, 255, 255, 0.8);
            font-size: 17px;
            margin-top: 10px;
            font-family: "Microsoft YaHei";
            line-height: 1.42857143;
            small {
              display: block;
              font-size: 12px;
              line-height: 1.42857143;
            }
          }
        }
        .user-footer {
          background-color: #f9f9f9;
          padding: 10px;
          .pull-left {
            float: left !important;
            font-size: 14px;
            text-align: left;
            list-style: none;
            font-family: "Microsoft YaHei";
            line-height: 1.42857143;
            color: #666666;
            background-color: #f4f4f4;
            border: 1px solid #ddd;
            padding: 6px 12px;
            text-align: center;
            cursor: pointer;
          }
          .pull-right {
            float: right !important;
            font-size: 14px;
            text-align: left;
            list-style: none;
            font-family: "Microsoft YaHei";
            line-height: 1.42857143;
            color: #666666;
            background-color: #f4f4f4;
            border: 1px solid #ddd;
            padding: 6px 12px;
            text-align: center;
            cursor: pointer;
          }
        }
        .user-footer::after {
          content: "";
          display: table;
          clear: both;
        }
      }
    }
  }

  //主体内容区域
  .main-content {
    display: flex;
    height: auto;
    // min-height: 1000px;
    min-height: calc(100vh - 50px);
    .navigation {
      width: 230px;
      background-color: #222d32;
      flex-shrink: 0;
      transition: width 0.3s ease-in-out;
      .input-box {
        padding: 10px;
        width: calc(100% - 22px);
        position: relative;
        overflow: hidden;
        .form-control {
          outline: none;
          border: none;
          background-color: #374850;
          border: 1px solid transparent;
          width: 156px;
          border-radius: 4px;
          padding: 7.5px 42px 7.5px 12px;
          font-size: 14px;
          line-height: 1.42857143;
          font-family: inherit;
          color: #796666;
          opacity: 1;
        }
        .search {
          position: absolute;
          width: 38.6px;
          height: 36.6px;
          line-height: 36.6px;
          text-align: center;
          right: 10px;
          color: #796666;
          font-size: 14px;
          opacity: 1;
          .fa {
            color: #999;
          }
        }
        .form-control:focus {
          background-color: #fff;
        }
      }
      .main-navigation {
        height: 37.15px;
        line-height: 37.15px;
        width: calc(100% - 15px);
        background-color: #1a2226;
        font-family: "Microsoft YaHei";
        font-size: 12px;
        color: #4b646f;
        padding-left: 15px;
      }
      .navigation-content {
        cursor: pointer;
        width: 100%;
        .navigation-open-1 {
          width: 100%;
          height: 44px;
          color: #b8c7ce;
          font-size: 14px;
          line-height: 44px;
          font-weight: 400;
          font-family: "Microsoft YaHei";
          border-left: 3px solid transparent;
          position: relative;
          box-sizing: border-box;
          .fa {
            width: 18px;
            font-size: 14px;
            margin-left: 13px;
            box-sizing: border-box;
          }
          .ion {
            width: 18px;
            font-size: 14px;
            margin-left: 13px;
            box-sizing: border-box;
          }
          .fa-angle-left {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 0;
            width: auto;
          }
          .pull-rotate {
            transform: translateY(-50%) rotate(-90deg);
          }
        }
        .navigation-open-1-active {
          border-left-color: #3c8dbc;
          color: #fff;
          background-color: #1a2226;
        }
        .navigation-open-1:hover {
          border-left-color: #3c8dbc;
          color: #fff;
          background-color: #1a2226;
        }
        .navigation-open-2-box {
          overflow: hidden;
          height: 0;
          transition: height 0.5s;
          background-color: #2c3b41;
          .navigation-open-2 {
            width: 230px;
            height: 30px;
            color: #b8c7ce;
            font-size: 14px;
            line-height: 30px;
            font-weight: 400;
            font-family: "Microsoft YaHei";
            border-left: 3px solid transparent;
            position: relative;
            border: none;
            .fa {
              width: 20px;
              font-size: 14px;
              margin-left: 18px;
              box-sizing: border-box;
            }
            .fa-angle-left {
              position: absolute;
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
              width: auto;
            }
            .pull-rotate {
              transform: translateY(-50%) rotate(-90deg);
            }
          }
          .navigation-open-2:hover {
            color: #fff;
          }
          .navigation-open-2-active {
            color: #fff;
          }
        }
        .navigation-open-3-box {
          transition: height 0.5s;
          background-color: #2c3b41;
          overflow: hidden;
          height: 0;
          .navigation-open-3 {
            width: 230px;
            height: 30px;
            color: #b8c7ce;
            font-size: 14px;
            line-height: 30px;
            font-weight: 400;
            font-family: "Microsoft YaHei";
            border-left: 3px solid transparent;
            border: none;
            position: relative;
            .fa {
              width: 20px;
              font-size: 14px;
              margin-left: 36px;
              box-sizing: border-box;
            }
            .fa-angle-left {
              position: absolute;
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
              width: auto;
            }
            .pull-rotate {
              transform: translateY(-50%) rotate(-90deg);
            }
          }
          .navigation-open-3:hover {
            color: #fff;
          }
          .navigation-open-3-active {
            color: #fff;
          }
        }
        .navigation-open-4-box {
          transition: height 0.5s;
          background-color: #2c3b41;
          overflow: hidden;
          height: 0;
          .navigation-open-4 {
            width: calc(100% - 3px);
            height: 30px;
            color: #b8c7ce;
            font-size: 14px;
            line-height: 30px;
            font-weight: 400;
            font-family: "Microsoft YaHei";
            border-left: 3px solid transparent;
            position: relative;
            .fa {
              width: 20px;
              font-size: 14px;
              margin-left: 54px;
              box-sizing: border-box;
            }
            .fa-angle-left {
              position: absolute;
              right: 20px;
              top: 50%;
              transform: translateY(-50%);
              width: auto;
            }
            .pull-rotate {
              transform: translateY(-50%) rotate(-90deg);
            }
          }
          .navigation-open-4:hover {
            color: #fff;
          }
          .navigation-open-4-active {
            color: #fff;
          }
        }
        .navigation-open-5-box {
          transition: height 0.5s;
          background-color: #2c3b41;
          overflow: hidden;
          height: 0;
          .navigation-open-5 {
            width: 100%;
            height: 30px;
            color: #b8c7ce;
            font-size: 14px;
            line-height: 30px;
            font-weight: 400;
            font-family: "Microsoft YaHei";
            border-left: 3px solid transparent;
            .fa {
              width: 20px;
              font-size: 14px;
              margin-left: 72px;
              box-sizing: border-box;
            }
            .fa-angle-left {
              line-height: 30px;
              margin-right: 10px;
              // margin-left: 0;
              // width: auto;
            }
          }
          .navigation-open-5:hover {
            color: #fff;
          }
          .navigation-open-5-active {
            color: #fff;
          }
        }
      }

      .navigation-content-close {
        cursor: pointer;
        width: 100%;
        position: relative;
        .navigation-open-1 {
          width: 100%;
          height: 44px;
          color: #b8c7ce;
          font-size: 14px;
          line-height: 44px;
          font-weight: 400;
          font-family: "Microsoft YaHei";
          border-left: 3px solid transparent;
          position: relative;
          box-sizing: border-box;
          .fa {
            width: 18px;
            font-size: 14px;
            margin-left: 14px;
            box-sizing: border-box;
          }
          .ion {
            width: 18px;
            font-size: 14px;
            margin-left: 14px;
            box-sizing: border-box;
          }
          .fa-angle-left {
            position: absolute;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
            margin-left: 0;
            width: auto;
          }
          .pull-rotate {
            transform: translateY(-50%) rotate(-90deg);
          }
        }
        .navigation-open-1-active {
          border-left-color: #3c8dbc;
          color: #fff;
          background-color: #1a2226;
        }
        .navigation-open-1:hover {
          border-left-color: #3c8dbc;
          color: #fff;
          background-color: #1a2226;
        }
        .navigation-content-abs {
          position: absolute;
          top: 0;
          left: 50px;
          width: 180px;
          display: none;
          white-space: nowrap;
          z-index: 100;
          .title-box {
            height: 44px;
            line-height: 44px;
            width: calc(100% - 20px);
            border-radius: 0 3px 3px 0;
            padding-left: 20px;
            box-sizing: content-box;
            background-color: #1a2226;
            color: #fff;
          }
          .navigation-open-2-box {
            height: auto;
            transition: height 0.5s;
            background-color: #2c3b41;
            width: 180px;
            border-radius: 0 3px 3px 0;
            .navigation-open-2 {
              width: 180px;
              height: 30px;
              color: #b8c7ce;
              font-size: 14px;
              line-height: 30px;
              font-weight: 400;
              font-family: "Microsoft YaHei";
              border-left: 3px solid transparent;
              position: relative;
              border: none;
              .fa {
                width: 20px;
                font-size: 14px;
                margin-left: 18px;
                box-sizing: border-box;
              }
              .fa-angle-left {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: auto;
              }
              .pull-rotate {
                transform: translateY(-50%) rotate(-90deg);
              }
            }
            .navigation-open-2:hover {
              color: #fff;
            }
            .navigation-open-2-active {
              color: #fff;
            }
          }
          .navigation-open-3-box {
            transition: height 0.5s;
            background-color: #2c3b41;
            overflow: hidden;
            height: 0;
            .navigation-open-3 {
              width: 230px;
              height: 30px;
              color: #b8c7ce;
              font-size: 14px;
              line-height: 30px;
              font-weight: 400;
              font-family: "Microsoft YaHei";
              border-left: 3px solid transparent;
              border: none;
              position: relative;
              .fa {
                width: 20px;
                font-size: 14px;
                margin-left: 36px;
                box-sizing: border-box;
              }
              .fa-angle-left {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: auto;
              }
              .pull-rotate {
                transform: translateY(-50%) rotate(-90deg);
              }
            }
            .navigation-open-3:hover {
              color: #fff;
            }
            .navigation-open-3-active {
              color: #fff;
            }
          }
          .navigation-open-4-box {
            transition: height 0.5s;
            background-color: #2c3b41;
            overflow: hidden;
            height: 0;
            .navigation-open-4 {
              width: calc(100% - 3px);
              height: 30px;
              color: #b8c7ce;
              font-size: 14px;
              line-height: 30px;
              font-weight: 400;
              font-family: "Microsoft YaHei";
              border-left: 3px solid transparent;
              position: relative;
              .fa {
                width: 20px;
                font-size: 14px;
                margin-left: 54px;
                box-sizing: border-box;
              }
              .fa-angle-left {
                position: absolute;
                right: 20px;
                top: 50%;
                transform: translateY(-50%);
                width: auto;
              }
              .pull-rotate {
                transform: translateY(-50%) rotate(-90deg);
              }
            }
            .navigation-open-4:hover {
              color: #fff;
            }
            .navigation-open-4-active {
              color: #fff;
            }
          }
          .navigation-open-5-box {
            transition: height 0.5s;
            background-color: #2c3b41;
            overflow: hidden;
            height: 0;
            .navigation-open-5 {
              width: 100%;
              height: 30px;
              color: #b8c7ce;
              font-size: 14px;
              line-height: 30px;
              font-weight: 400;
              font-family: "Microsoft YaHei";
              border-left: 3px solid transparent;
              .fa {
                width: 20px;
                font-size: 14px;
                margin-left: 72px;
                box-sizing: border-box;
              }
              .fa-angle-left {
                line-height: 30px;
                margin-right: 10px;
                // margin-left: 0;
                // width: auto;
              }
            }
            .navigation-open-5:hover {
              color: #fff;
            }
            .navigation-open-5-active {
              color: #fff;
            }
          }
        }
      }
      .navigation-content-close:hover {
        .navigation-content-abs {
          display: block;
          z-index: 100;
        }
      }
    }
    .navigation-close {
      width: 50px;
    }
    .navigation-none {
      width: 0;
    }
    .right {
      width: 100%;
    }
  }

  .modal {
    background: rgba(0, 0, 0, 0.3);
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
  }
}
</style>

<style scoped>
/* Chrome */
.form-control::-webkit-input-placeholder {
  color: #999999;
}
/* Firefox */
.form-control::-moz-placeholder {
  color: #999999;
  opacity: 1;
}
/* IE */
.form-control:-ms-input-placeholder {
  color: #999999;
}
</style>



<style lang="scss" scoped>
// 媒体查询
@media only screen and (max-width: 767px) {
  .main {
    .main-top {
      flex-wrap: wrap;
      .left {
        width: 100%;
      }
      .right {
        width: 100%;
        .user-box {
          padding: 10px 15px;
          .user-img {
            margin-right: 0;
            position: relative;
            top: -2.6px;
          }
          .user-name {
            display: none;
          }
        }
        .pop-frame {
          right: 5%;
          top: 51px;
        }
      }
    }
  }
}
</style>