import axios from "axios";

import { Message } from "element-ui";
import router from "@/router";

// 创建axios实例

const service = axios.create({
  baseURL: process.env.VUE_APP_SERVER,
  timeout: 500000, // 请求超时
  withCredentials: true,
});
// let cancel = null;
//请求拦截
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

//相应拦截
service.interceptors.response.use(
  (response) => {
    const res = response.data;
    if (res.status == 403) {
      if (localStorage.getItem("userInfo")) {
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        if (!userInfo.IsAdmin) {
          location.href = "https://hrms.baishulai.com/module/permissions";
        } else {
          location.href = "https://hrms.baishulai.com/NoPermissions.aspx";
        }
      } else {
        location.href = "https://hrms.baishulai.com/NoPermissions.aspx";
      }
    }
    if (res.status && res.status !== 200) {
      Message({
        showClose: true,
        message: res.msg,
        duration: 3000,
        type: "error",
      });
    }
    return res;
  },
  (error) => {
    if (error.response) {
      const res = error.response.data;
      Message({
        showClose: true,
        message: res.msg,
        duration: 3000,
        type: "error",
      });
      if (res.status === 401) {
        router.push("/signin");
      }
      if (res.status == 403) {
        if (localStorage.getItem("userInfo")) {
          let userInfo = JSON.parse(localStorage.getItem("userInfo"));
          if (!userInfo.IsAdmin) {
            location.href = "https://hrms.baishulai.com/module/permissions";
          } else {
            location.href = "https://hrms.baishulai.com/NoPermissions.aspx";
          }
        } else {
          location.href = "https://hrms.baishulai.com/NoPermissions.aspx";
        }
      }
    }
  }
);

export default service;
